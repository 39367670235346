import PropTypes from 'prop-types';
import Tippy from '@tippyjs/react';
import './tooltip.scss';

const Tooltip = ({ children, dangerousInput, ...rest }) => {
    if (!children) return null;
    if (dangerousInput) rest.content = <div dangerouslySetInnerHTML={{ __html: dangerousInput }} />;

    return (
        <Tippy theme="omnidots-white-shadow" {...rest}>
            {children}
        </Tippy>
    );
};

Tooltip.displayName = 'Tooltip';

Tooltip.propTypes = {
    children: PropTypes.node.isRequired,
    dangerousInput: PropTypes.string,
};

export default Tooltip;

const defaultTheme = require('tailwindcss/defaultTheme');

module.exports = {
    content: [
        './assets/js/**/*.{html,js}',
        '!./htmlcov/**/*', // Prevent wasting time on the coverage folders.
        './**/*.html',
    ],
    theme: {
        extend: {
            colors: {
                'main-background': '#FAFAFA',
                'main-background-odd': '#EFEFEF',
                'form-text': '#5C6160',
                'form-warning': 'rgb(252, 165, 165)',
                'menu-background': 'rgba(255, 255, 255, 0.2)',
                'data-type-grey': 'rgb(245, 245, 245)',
                'disabled-data-type-button': 'rgb(228, 228, 228)',
                'light-gray': 'rgb(210, 210, 210)',
                'disabled-data-type-button-border-hover': 'rgb(185 163 34)',
                'menu-color': 'rgb(119,119,119)',
                'hc-dark-grey': '#595959',
                'button-hover': 'rgb(230,198,7)',
                'menu-hover': 'rgb(238,238,238)',
                primary: '#F8DA28',
                secondary: '#B0B0B0',
                success: '#34C759',
                error: '#F34E4E',
                warning: '#FFAD31',
                'gray-outline': '#D9D9D9',
                'oslo-gray': '#848888',
                'dark-gray': '#9DA1AD',
                'daily-values-yellow': '#FFB000',
                'daily-values-orange': '#FE6100',
                'daily-values-magenta': '#DC267F',
                'daily-values-purple': '#785EF0',
                'daily-values-blue': '#648FFF',
                'graph-blue': '#4B5CF1',
                'banner-green': '#69A84F',
                input: {
                    green: '#30701A',
                    orange: '#D97826',
                    red: '#D53419',
                },
            },
            fontFamily: {
                sans: ['Titillium Web', ...defaultTheme.fontFamily.sans],
                display: ['Sora', ...defaultTheme.fontFamily.sans],
            },
            screens: {
                '2xl': '1600px',
                xl: '1240px',
            },
            boxShadow: {
                '448xl': '4px 4px 8px rgba(0, 0, 0, 0.25)',
                '044xl': '0px 4px 4px rgba(0, 0, 0, 0.25)',
                menu: '0 0.4em 0.6em 0 rgba(217, 226, 233, 0.5)',
            },
            width: {
                90: '22.5rem',
                571: '571px',
            },
            letterSpacing: {
                widened: '0.22px',
            },
            borderWidth: {
                0.5: '0.5px',
            },
            keyframes: {
                slideUp: {
                    '0%': { transform: 'translateY(100%)' },
                    '25%': { transform: 'translateY(75%)' },
                    '50%': { transform: 'translateY(50%)' },
                    '75%': { transform: 'translateY(25%)' },
                    '100%': { transform: 'translateY(0%)' },
                },
            },
            animation: {
                'slide-up': 'slideUp 0.3s linear',
            },
            fontSize: {
                xxs: '0.5rem',
                '2xs': ['10px', { lineHeight: '14px' }],
            },
        },
    },
};

import { flatMap } from 'lodash-es';
import { combineLatestWith, EMPTY, map, switchMap } from 'rxjs';
import warning from '../../utils/logger';
import { dustGraphDataTypes } from '../data-types';
import { BarGraphType, DotGraphType, LineGraphType, StepGraphType } from '../types';
import {
    BaseGraphElement,
    BaseGraphElementsProvider,
    filterElementsOnAxesWithLegend,
    sortAxis,
    valueNotNull,
} from './base-provider';

const availableAxis = ['x', 'y', 'z', 'vector'];

export class SampleAxisGraphElement extends BaseGraphElement {
    constructor(record, dataType, axis) {
        super(record);

        this.dataType = dataType;
        this.axis = axis;
    }

    get timestamp() {
        return this.record[this.dataType]?.timestamp;
    }

    getValueForDataType(dataType) {
        return this.record[dataType]?.[this.axis] ?? null;
    }

    get value() {
        return this.getValueForDataType(this.dataType);
    }

    static createCollectionFromRecords(records, dataType) {
        return flatMap(records, (record) =>
            availableAxis
                .map((axis) => new this(record, dataType, axis))
                .filter(valueNotNull)
                .sort(sortAxis)
        );
    }

    getVperPeriodForTimestamp(timestamp, dataSet) {
        return dataSet.vperPeriods.find((period) => {
            const { from, to } = period.timespan;

            return from <= timestamp && timestamp <= to;
        });
    }
}

/**
 * Observables flow diagram: https://miro.com/app/board/uXjVPSSWJQ0=/
 */
export class SamplesGraphElementsProvider extends BaseGraphElementsProvider {
    static getGraphElementClass() {
        return SampleAxisGraphElement;
    }

    static createGraphElementsObservable(dataSet, legendData) {
        return dataSet.samples$.pipe(
            combineLatestWith(dataSet.dataType$),
            map(([records, dataType]) =>
                this.getGraphElementClass().createCollectionFromRecords(records, dataType)
            ),
            filterElementsOnAxesWithLegend(legendData)
        );
    }

    // Extra class for the renderer, useful for D3 to distinguish DOM
    // elements manager by different renderers.
    getD3IdentifierCssClass() {
        return 'not-dust';
    }

    init() {
        const numberOfRecords = 365;
        const rectWidth = this.graph.graphWidth / numberOfRecords;

        const graphTypes = {
            line: [LineGraphType, []],
            step: [StepGraphType, []],
            bar: [BarGraphType, [rectWidth]],
            dot: [DotGraphType, [rectWidth]],
        };

        const renderer$ = this.graph.graphType$.pipe(
            switchMap((graphType) => {
                if (!Object.keys(graphTypes).includes(graphType)) {
                    warning(`No renderer for graphType ${graphType}`);
                    return EMPTY;
                }

                const [Renderer, args] = graphTypes[graphType];
                return Renderer.createRenderObservable(
                    this.graphElementPositions$,
                    this.graph,
                    this.getD3IdentifierCssClass(),
                    ...args
                );
            })
        );

        this.subscription.add(renderer$.subscribe());
    }

    getShouldCalculateElementPositionsObservable() {
        return this.dataSet.dataType$.pipe(
            map((dataType) => !dustGraphDataTypes.includes(dataType))
        );
    }
}

import { findKey, matches, stubFalse, without, isFunction } from 'lodash-es';
import { of, map, combineLatest } from 'rxjs';
import { distanceConverter, replaceDistanceUnit, roundToTwo } from '../utils/formatting';
import { applyNamingConvention, namingConventions$ } from '../utils/naming-conventions';
import { ContentTab, SwarmType } from '../enums';
import { getSoundUnit$ } from './graph-elements/sound-plugin/unit-formatter';
import { LnLegendTable } from './graph-elements/sound-plugin/LnLegendTable';

export const DataTypes = Object.freeze({
    VTOP: 'vtop',
    ATOP: 'atop',
    FDOM: 'fdom',
    VEFF: 'veff',
    VDV: 'vdv',

    PM10: 'PM10',
    PM2P5: 'PM2P5',
    FLOW: 'flow',
    RH: 'relativeHumidity',
    TEMP: 'temperature',

    LXN: 'lXn',
    LXEQ: 'lXEq',
    LXTMAX: 'lXtMax',
    LXPEAK: 'lXPeak',
    DAILY_VALUES: 'dailyValues',
    LDEN: 'lden',
});

export function getDataTypeKey(value) {
    return findKey(DataTypes, matches(value));
}

export const defaultDataType = Object.freeze({
    [SwarmType.VIBRATION]: DataTypes.VTOP,
    [SwarmType.AIR]: DataTypes.PM10,
    [SwarmType.SOUND]: DataTypes.LXEQ,
});

export const vibrationGraphDataTypes = [
    DataTypes.VTOP,
    DataTypes.FDOM,
    DataTypes.ATOP,
    DataTypes.VEFF,
    DataTypes.VDV,
];

export const dustGraphDataTypes = [
    DataTypes.PM10,
    DataTypes.PM2P5,
    DataTypes.FLOW,
    DataTypes.RH,
    DataTypes.TEMP,
];

export const swarmTypeDataTypes = {
    [SwarmType.VIBRATION]: vibrationGraphDataTypes,
    [SwarmType.AIR]: dustGraphDataTypes,
    [SwarmType.SOUND]: [
        DataTypes.LXEQ,
        DataTypes.LXTMAX,
        DataTypes.DAILY_VALUES,
        DataTypes.LXN,
        DataTypes.LXPEAK,
        DataTypes.LDEN,
    ],
};

export const SCALES_TYPE = Object.freeze({
    AUTO: 'AUTO',
    LINEAR: 'LINEAR',
    LOGARITHMIC: 'LOGARITHMIC',
});

export const dustGraphDataTypesWithoutPM10 = without(dustGraphDataTypes, DataTypes.PM10);
export const dustGraphDataTypesWithoutPM2p5 = without(dustGraphDataTypes, DataTypes.PM2P5);

class DataType {
    constructor(options) {
        Object.assign(this, {
            // Defaults:
            convertDistance: false,
            valueScaleMin: 0,
            defaultGraphType: 'bar',
            showFrequencyGraph: false,
            showGuideLineLines: false,
            showVssTable: false,
            showVeffDayGraph: false,
            showVperLines: false,
            frequencyGraphScaleType: SCALES_TYPE.LINEAR,
            contentTab: ContentTab.GRAPH,
            ...options,
        });

        // Change the unit to the proper value.
        if (this.convertDistance) {
            this.unit = replaceDistanceUnit(this.unit);
        }

        // Cached version of `matches(this.configMatcherQuery)(config)`.
        if (isFunction(this.configMatcherQuery)) {
            this.configMatcher = this.configMatcherQuery;
        } else if (this.configMatcherQuery) {
            this.configMatcher = matches(this.configMatcherQuery);
        } else {
            this.configMatcher = stubFalse;
        }

        // Cached version of `matches(this.configMatcherQuery)(event)`.
        this.eventConfigMatcher = (event) => this.configMatcher(event.config);
    }

    getUnit$(_dataSet) {
        return of(this.unit);
    }

    getAxisTitle$(dataSet) {
        // Use the axis title if this data type has one.
        if (this.axisTitle) {
            return of(this.axisTitle);
        }

        // Data type has no axis title, we create it from the data title and data unit.
        return combineLatest([namingConventions$, this.getUnit$(dataSet)]).pipe(
            map(([_namingConventions, unit]) => {
                let text = applyNamingConvention(this.title);
                if (unit) {
                    text += ` [${unit}]`;
                }
                return text;
            })
        );
    }

    getLegendTooltipContent$(graph, dataSet) {
        return graph.tooltipHelper.data$.pipe(
            // eslint-disable-next-line no-use-before-define
            map((data) => fixFloat(dataSet.dataType, data.element.value))
        );
    }
}

const pmAmountAfterDot = 2;

const pmList = ['pm10', 'pm2p5'].flatMap((pmType) => [
    {
        key: `${pmType}1m`,
        title: `${pmType.toUpperCase()} 1min`,
        unit: 'μg/m3',
        amountAfterDot: pmAmountAfterDot,
    },
    {
        key: `${pmType}15m`,
        title: `${pmType.toUpperCase()} 15min`,
        unit: 'μg/m3',
        amountAfterDot: pmAmountAfterDot,
    },
    {
        key: `${pmType}60m`,
        title: `${pmType.toUpperCase()} 1hour`,
        unit: 'μg/m3',
        amountAfterDot: pmAmountAfterDot,
    },
    {
        key: `${pmType}600m`,
        title: `${pmType.toUpperCase()} 10hour`,
        unit: 'μg/m3',
        amountAfterDot: pmAmountAfterDot,
    },
    {
        key: `${pmType}1440m`,
        title: `${pmType.toUpperCase()} 24hour`,
        unit: 'μg/m3',
        amountAfterDot: pmAmountAfterDot,
    },
]);

function soundHasIntervalEnabled(intervalName) {
    return function intervalEnabledCheck(config) {
        return (
            config.swarmType === SwarmType.SOUND &&
            intervalName in config &&
            config[intervalName] !== 0
        );
    };
}

export const dataTypes = [
    {
        key: DataTypes.VTOP,
        title: '~~vtop~~',
        graphName: gettext('~~vtop_ext~~/time'),
        unit: 'mm/s',
        visibleData: ['vtop', 'fdom'],
        legendExcludeTypes: [/^pm10/, /^pm2p5/, /^vper/, ...dustGraphDataTypes],
        amountAfterDot: 4,
        convertDistance: true,
        showFrequencyGraph: true,
        showGuideLineLines: true,
        valueScaleInitial: 10,
        valueScaleMax: 300,
        configMatcherQuery: {
            vtopEnabled: true,
            swarmType: SwarmType.VIBRATION,
        },
    },
    {
        key: DataTypes.ATOP,
        title: '~~atop~~',
        graphName: gettext('~~atop_ext~~/time'),
        unit: 'mm/s²',
        visibleData: ['atop'],
        legendExcludeTypes: [/^pm10/, /^pm2p5/, /^vper/, ...dustGraphDataTypes],
        amountAfterDot: 4,
        convertDistance: true,
        valueScaleInitial: 1300,
        valueScaleMax: 4000,
        configMatcherQuery: {
            atopEnabled: true,
            swarmType: SwarmType.VIBRATION,
        },
    },
    {
        key: DataTypes.VEFF,
        title: '~~veff_max~~',
        graphName: gettext('~~veff_max_ext~~/time'),
        unit: null,
        visibleData: ['veff', 'vper', 'vmax'],
        legendExcludeTypes: [/^pm10/, /^pm2p5/, 'vector', ...dustGraphDataTypes],
        amountAfterDot: 2,
        convertDistance: false,
        showVssTable: true,
        showVeffDayGraph: true,
        showVperLines: true,
        valueScaleInitial: 10,
        valueScaleMax: 100,
        configMatcherQuery: {
            veffEnabled: true,
            swarmType: SwarmType.VIBRATION,
        },
    },
    {
        key: DataTypes.VDV,
        title: 'VDV',
        graphName: gettext('VDV/time'),
        unit: 'm/s–1.75',
        visibleData: ['vdv'],
        legendExcludeTypes: [/^pm10/, /^pm2p5/, 'vector', /^vper/, ...dustGraphDataTypes],
        amountAfterDot: 4,
        valueScaleInitial: 10,
        valueScaleMax: 100,
        configMatcherQuery: {
            vdvEnabled: true,
            swarmType: SwarmType.VIBRATION,
        },
    },
    {
        key: DataTypes.FDOM,
        title: 'Fdom',
        axisTitle: gettext('Dominant frequency [Hz]'),
        graphName: gettext('Frequency/time'),
        unit: 'Hz',
        visibleData: ['fdom', 'vtop'],
        legendExcludeTypes: [/^pm10/, /^pm2p5/, /^vper/, ...dustGraphDataTypes],
        amountAfterDot: 1,
        showFrequencyGraph: true,
        valueScaleInitial: 315.0, // Hz
        valueScaleMax: 315.0, // Hz
        configMatcherQuery: {
            vtopEnabled: true,
            swarmType: SwarmType.VIBRATION,
        },
    },
    {
        key: DataTypes.PM10,
        title: 'PM10',
        graphName: gettext('Dust/time'),
        unit: 'μg/m3',
        visibleData: [/^pm10/],
        legendExcludeTypes: [
            'x',
            'y',
            'z',
            'vector',
            /^vper/,
            /^pm2p5/,
            ...dustGraphDataTypesWithoutPM10,
        ],
        amountAfterDot: pmAmountAfterDot,
        valueScaleInitial: 50,
        valueScaleMax: 100000,
        configMatcherQuery: { swarmType: SwarmType.AIR },
        defaultGraphType: 'line',
    },
    {
        key: DataTypes.PM2P5,
        title: 'PM2.5',
        graphName: gettext('Dust/time'),
        unit: 'μg/m3',
        visibleData: [/^pm2p5/],
        legendExcludeTypes: [
            'x',
            'y',
            'z',
            'vector',
            /^vper/,
            /^pm10/,
            ...dustGraphDataTypesWithoutPM2p5,
        ],
        amountAfterDot: pmAmountAfterDot,
        valueScaleInitial: 50,
        valueScaleMax: 100000,
        configMatcherQuery: { swarmType: SwarmType.AIR },
        defaultGraphType: 'line',
    },
    {
        key: DataTypes.FLOW,
        title: gettext('Flow'),
        graphName: gettext('Flow/time'),
        unit: 'l/m',
        visibleData: [DataTypes.FLOW],
        legendExcludeTypes: [
            'x',
            'y',
            'z',
            'vector',
            /^vper/,
            /^pm10/,
            /^pm2p5/,
            ...without(dustGraphDataTypes, DataTypes.FLOW),
        ],
        amountAfterDot: pmAmountAfterDot,
        valueScaleInitial: 2.5,
        valueScaleMax: 2.5,
        configMatcherQuery: { swarmType: SwarmType.AIR },
        defaultGraphType: 'line',
    },
    {
        key: DataTypes.RH,
        title: gettext('Relative humidity'),
        graphName: gettext('Relative humidity/time'),
        unit: '%',
        visibleData: [DataTypes.RH],
        legendExcludeTypes: [
            'x',
            'y',
            'z',
            'vector',
            /^vper/,
            /^pm10/,
            /^pm2p5/,
            ...without(dustGraphDataTypes, DataTypes.RH),
        ],
        amountAfterDot: pmAmountAfterDot,
        valueScaleInitial: 100,
        valueScaleMax: 100,
        configMatcherQuery: { swarmType: SwarmType.AIR },
        defaultGraphType: 'line',
    },
    {
        key: DataTypes.TEMP,
        title: gettext('Temperature'),
        graphName: gettext('Temperature/time'),
        unit: '°C',
        visibleData: [DataTypes.TEMP],
        legendExcludeTypes: [
            'x',
            'y',
            'z',
            'vector',
            /^vper/,
            /^pm10/,
            /^pm2p5/,
            ...without(dustGraphDataTypes, DataTypes.TEMP),
        ],
        amountAfterDot: pmAmountAfterDot,
        valueScaleInitial: 50,
        valueScaleMax: 55,
        valueScaleMin: -11,
        configMatcherQuery: { swarmType: SwarmType.AIR },
        defaultGraphType: 'line',
    },
    {
        key: DataTypes.LXEQ,
        title: 'Leq',
        graphName: gettext('LEQ_GRAPH_NAME'),
        unit: 'dB',
        getUnit$(dataSet) {
            return getSoundUnit$(dataSet);
        },
        visibleData: [DataTypes.LXEQ],
        legendExcludeTypes: [/^pm10/, /^pm2p5/, /^vper/, ...dustGraphDataTypes],
        amountAfterDot: 1,
        convertDistance: false,
        showFrequencyGraph: false,
        showGuideLineLines: false,
        useNewLegend: true,
        followMouseWithLine: true,
        onlyShowToolTipForEvents: true,
        valueScaleInitial: 80,
        valueScaleMax: 140,
        configMatcherQuery: soundHasIntervalEnabled('lxEqInterval'),
        defaultGraphType: 'step',
    },
    {
        key: DataTypes.LXTMAX,
        title: 'Lmax',
        graphName: gettext('LMAX_GRAPH_NAME'),
        unit: 'dB',
        getUnit$(dataSet) {
            return getSoundUnit$(dataSet);
        },
        visibleData: [DataTypes.LXTMAX],
        legendExcludeTypes: [/^pm10/, /^pm2p5/, /^vper/, ...dustGraphDataTypes],
        amountAfterDot: 1,
        convertDistance: false,
        showFrequencyGraph: false,
        showGuideLineLines: false,
        useNewLegend: true,
        followMouseWithLine: true,
        onlyShowToolTipForEvents: true,
        valueScaleInitial: 80,
        valueScaleMax: 140,
        configMatcherQuery: soundHasIntervalEnabled('lXtMinMaxInterval'),
        defaultGraphType: 'step',
    },
    {
        key: DataTypes.DAILY_VALUES,
        title: gettext('DAILY_VALUES'),
        contentTab: ContentTab.DAILY_VALUES,

        graphName: gettext('DAILY_VALUES'),
        unit: 'dB',
        visibleData: [DataTypes.DAILY_VALUES],
        legendExcludeTypes: [/^pm10/, /^pm2p5/, /^vper/, ...dustGraphDataTypes],
        amountAfterDot: 1,
        convertDistance: false,
        showFrequencyGraph: false,
        showGuideLineLines: false,
        useNewLegend: true,
        followMouseWithLine: true,
        onlyShowToolTipForEvents: true,
        valueScaleInitial: 80,
        valueScaleMax: 140,
        configMatcherQuery: soundHasIntervalEnabled('lxEqInterval'),
        defaultGraphType: 'step',
    },
    {
        key: DataTypes.LXN,
        title: 'Ln',

        graphName: gettext('Ln'),
        unit: 'dB',
        getUnit$(dataSet) {
            return getSoundUnit$(dataSet);
        },
        visibleData: [DataTypes.LXN],
        legendExcludeTypes: [/^pm10/, /^pm2p5/, /^vper/, ...dustGraphDataTypes],
        amountAfterDot: 1,
        convertDistance: false,
        showFrequencyGraph: false,
        showGuideLineLines: false,
        useNewLegend: true,
        followMouseWithLine: true,
        onlyShowToolTipForEvents: true,
        valueScaleInitial: 80,
        valueScaleMax: 140,
        configMatcherQuery: soundHasIntervalEnabled('lXnInterval'),
        defaultGraphType: 'step',
        getLegendTooltipContent$(graph, _dataSet) {
            return graph.tooltipHelper.data$.pipe(
                map((data) => {
                    // Check if the record's data type is 'LXN'. Due to asynchronous execution
                    // of observables, there's a chance that the tooltip handler may switch to
                    // a different data type before the legend content renderer does. If the data
                    // type does not match 'LXN', return null to prevent errors.
                    if (data.element.record.__typename !== 'LXN') {
                        return null;
                    }

                    return (
                        <LnLegendTable
                            lnValues={data.element.record.value}
                            // eslint-disable-next-line no-use-before-define
                            fixFloat={(v) => fixFloat(DataTypes.LXN, v)}
                        />
                    );
                })
            );
        },
    },
    {
        key: DataTypes.LXPEAK,
        title: 'Lpeak',

        graphName: gettext('Lpeak'),
        unit: 'dB',
        getUnit$(dataSet) {
            return getSoundUnit$(dataSet);
        },
        visibleData: [DataTypes.LXN],
        legendExcludeTypes: [/^pm10/, /^pm2p5/, /^vper/, ...dustGraphDataTypes],
        amountAfterDot: 1,
        convertDistance: false,
        showFrequencyGraph: false,
        showGuideLineLines: false,
        useNewLegend: true,
        followMouseWithLine: true,
        onlyShowToolTipForEvents: true,
        valueScaleInitial: 80,
        valueScaleMax: 140,
        configMatcherQuery: soundHasIntervalEnabled('lxPeakInterval'),
        defaultGraphType: 'step',
    },
    {
        key: DataTypes.LDEN,
        title: gettext('LDEN'),
        contentTab: ContentTab.LDEN,
        graphName: gettext('LDEN'),
        unit: 'dB',
        visibleData: ['lXtMax'],
        legendExcludeTypes: [/^pm10/, /^pm2p5/, /^vper/, ...dustGraphDataTypes],
        amountAfterDot: 1,
        convertDistance: false,
        showFrequencyGraph: false,
        showGuideLineLines: false,
        useNewLegend: true,
        followMouseWithLine: true,
        onlyShowToolTipForEvents: true,
        valueScaleInitial: 80,
        valueScaleMax: 140,
        configMatcherQuery: soundHasIntervalEnabled('lxEqInterval'),
        defaultGraphType: 'step',
    },
    // Keep these last as they are no real graph types and thus shouldn't get detected by
    // the auto config detection system.
    // TODO: remove these https://github.com/omnidots/website/issues/6823
    {
        key: 'vper',
        title: '~~vper~~',
        graphName: gettext('~~vper~~ table'),
        valueNames: ['~~a1~~', '~~a2~~', '~~a3~~'],
        unit: null,
        amountAfterDot: 2,
    },
    {
        key: 'vmax',
        title: '~~vmax~~',
        unit: null,
        amountAfterDot: 2,
    },
    {
        key: 'stopCause',
        title: gettext('Cause'),
        unit: '',
    },
    {
        key: 'uploading',
        title: null,
        unit: '',
    },
    {
        key: 'startMessage',
        title: null,
        unit: '',
    },
    {
        key: 'name',
        title: null,
        unit: '',
    },
    ...pmList,
].map((options) => new DataType(options));

export const getDataType = (key) => {
    const found = dataTypes.find((dataType) => dataType.key === key);

    if (!found) {
        throw new Error(
            `DataType ${key} unknown. Did you forget to add it to the 'dataTypes' list? `
        );
    }

    return found;
};

/**
 * Finds the best matching data type for the supplied config.
 * For now, the data type preference is in the order of the
 * dataTypes array.
 *
 * @param {object} config - Config object returned from the API.
 * @return {DataType}
 */
export const findBestMatchingDataTypeForConfig = (config) =>
    dataTypes.find(({ configMatcher }) => configMatcher(config));

export const guideLineSettings = Object.freeze({
    SS_4604861: {
        showVssTable: false,
        showVeffDayGraph: false,
        showVperLines: false,
        frequencyGraphScaleType: SCALES_TYPE.LOGARITHMIC,
        legendExcludeTypes: [...getDataType(DataTypes.VEFF).legendExcludeTypes, /^vper/],
    },
    SS_025211: {
        frequencyGraphScaleType: SCALES_TYPE.LOGARITHMIC,
    },
});

export function getDataTypeKeyByValue(value) {
    return findKey(DataTypes, matches(value));
}

export function convertDistance(type, value) {
    if (getDataType(type).convertDistance) {
        return distanceConverter(value);
    }

    return value;
}

function isFloat(val) {
    return !!(val % 1);
}

export function fixFloat(type, value) {
    if (isFloat(value)) {
        return value.toFixed(getDataType(type).amountAfterDot);
    }

    return value;
}

export function convertDistanceAndFloat(type, value) {
    return fixFloat(type, convertDistance(type, value));
}

export function convertDistanceAndRoundToTwo(type, value) {
    return roundToTwo(convertDistance(type, value));
}

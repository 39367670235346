import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useObservable, useObservableEagerState, useObservableState } from 'observable-hooks';
import { findIndex, partial, reverse, sortBy } from 'lodash-es';
import { combineLatest, map, switchMap } from 'rxjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquare } from '@fortawesome/free-solid-svg-icons';
import { zonedTimeToUtc } from 'date-fns-tz';
import { addHours, subHours, startOfDay, endOfDay } from 'date-fns';

import { BaseDataSet } from '../../../graph/dataset';
import Table from '../../table/Table';
import { DataTypes, fixFloat, getDataType } from '../../../graph/data-types';
import { CursorFunction } from '../../../graph/cursor-functions';
import { RoundedButton } from '../../form/Button';

// This constant, DAILY_VALUE_COLORS, holds an array of CSS-class strings,
// each representing a color. The array is strictly ordered by the importance
// of triggers, with the first entry being the color for the most critical
// trigger, descending to the color for the least critical one.
const DAILY_VALUE_COLORS = [
    'text-daily-values-yellow', // Highest level trigger color (Most critical)
    'text-daily-values-orange',
    'text-daily-values-magenta',
    'text-daily-values-purple',
    'text-daily-values-blue', // Lowest level trigger color (Least critical)
];

function TriggerIcon({ color }) {
    return <FontAwesomeIcon icon={faSquare} className={color} />;
}
TriggerIcon.propTypes = {
    color: PropTypes.string.isRequired,
};

function useTableData(dataSet) {
    const values$ = useObservable(
        (inputs$) =>
            inputs$.pipe(
                switchMap(([incomingDataSet]) =>
                    combineLatest([
                        incomingDataSet.dailyValues$,
                        incomingDataSet.dailyValuesTriggers$,
                    ])
                ),
                map(([values, triggers]) => {
                    // Sorted triggers from high to low.
                    const sortedTriggers = reverse(sortBy(triggers, 'trigger'));

                    return values.map((valueEntry) => {
                        const triggerIndex = findIndex(
                            sortedTriggers,
                            (triggerEntry) => valueEntry.lXEq >= triggerEntry.trigger
                        );

                        const triggerEntry =
                            triggerIndex === -1 ? null : sortedTriggers[triggerIndex];

                        return {
                            ...valueEntry,
                            exceedance: triggerEntry && triggerEntry.trigger,
                            exceedanceColor: triggerEntry && DAILY_VALUE_COLORS[triggerIndex],
                        };
                    });
                })
            ),
        [dataSet]
    );

    return useObservableState(values$, []);
}

function lXEqFormatter(value, unit) {
    return `${fixFloat(DataTypes.LXEQ, value)}${unit}`;
}

function valueColumnRenderer(unit, _column, rowData) {
    return lXEqFormatter(rowData.lXEq, unit);
}

function exceedanceColumnRenderer(unit, _column, rowData) {
    const value = rowData.exceedance;

    if (!value) {
        return null;
    }

    return (
        <>
            <TriggerIcon color={rowData.exceedanceColor} />
            {` ≥ ${lXEqFormatter(rowData.exceedance, unit)}`}
        </>
    );
}

function calculateMinMaxDateRange(dateString, timezone, { start, end }) {
    const date = zonedTimeToUtc(dateString, timezone);
    let [min, max] = [startOfDay(date), endOfDay(date)];

    // Extend the range by 2 hours on each side if the time settings span the entire day.
    if (start === '00:00' && end === '23:59') {
        min = subHours(min, 2);
        max = addHours(max, 2);
    }

    return [min, max];
}

function viewColumnRenderer(dataSet, dailyValuesTimeSettings, _column, rowData) {
    return (
        <RoundedButton
            type="button"
            onClick={async () => {
                // Switch to the LXEQ tab.
                dataSet.setDatatype(DataTypes.LXEQ);

                // Calculate and set the min-max date range.
                const [min, max] = calculateMinMaxDateRange(
                    rowData.date,
                    dataSet.timezone,
                    dailyValuesTimeSettings
                );
                dataSet.setVisibleMinMax(min, max);

                // Switch to ZOOM cursor function to avoid accidentally
                // changes to the selection block.
                window.timeGraph.cursorFunction$.next(CursorFunction.ZOOM);

                // Create a selection block based on the daily value's time span.
                const selectionsPlugin = await window.timeGraph.getPlugin('selections');
                selectionsPlugin.selections.addManually(rowData.timeSpan.from, rowData.timeSpan.to);
            }}
            color="secondary"
            flex
        >
            {gettext('VIEW_IN_GRAPH')}
        </RoundedButton>
    );
}

function useUnit(dataSet) {
    const unit$ = useObservable(
        (inputs$) =>
            inputs$.pipe(switchMap(([data]) => getDataType(DataTypes.LXEQ).getUnit$(data))),
        [dataSet]
    );
    return useObservableEagerState(unit$);
}

function DailyValuesTable({ dataSet }) {
    const dailyValuesLoading = useObservableState(dataSet.dailyValuesLoading$, true);
    const dailyValuesTimeSettings = useObservableState(dataSet.dailyValuesTimeSettings$, null);
    const data = useTableData(dataSet);
    const unit = useUnit(dataSet);

    const columns = useMemo(
        () => [
            { key: 'date', title: gettext('DATE') },
            { key: 'lXEq', title: gettext('VALUE'), render: partial(valueColumnRenderer, unit) },
            {
                key: 'exceedance',
                title: gettext('EXCEEDANCE'),
                render: partial(exceedanceColumnRenderer, unit),
            },
            {
                key: 'view',
                title: '',
                render: partial(viewColumnRenderer, dataSet, dailyValuesTimeSettings),
            },
        ],
        [unit, dataSet, dailyValuesTimeSettings]
    );

    return <Table data={data} columns={columns} loading={dailyValuesLoading} showBorder={false} />;
}
DailyValuesTable.propTypes = {
    dataSet: PropTypes.instanceOf(BaseDataSet).isRequired,
};

export default DailyValuesTable;

/* eslint-disable no-useless-escape */
/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
/* eslint-disable func-names */
/* eslint-disable max-len */
/* eslint-disable no-use-before-define */
/* eslint-disable eqeqeq */
/* eslint-disable consistent-return */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-bitwise */
/* eslint-disable no-alert */
/* eslint-disable no-restricted-globals */
import 'tippy.js/dist/tippy.css';
import 'select2/dist/css/select2.css';

import $ from 'jquery';
import { ParseError, parsePhoneNumber } from 'libphonenumber-js';
import tippy from 'tippy.js';
import { times, identity, isUndefined } from 'lodash-es';
import { Subject, zip } from 'rxjs';
import profile from './profile';
import { lcFirst } from './utils/formatting';
import { applyNamingConvention } from './utils/naming-conventions';
import testSome from './utils/testSome';
import { to12h, to24h } from './utils/time';
import { ANY } from './components/pages/measuringPoint/configHelper';
import { ConfigConverter } from './components/pages/measuringPoint/configConverter';

$.fn.valFilter = function valFilter(val) {
    const matches = this.filter(function _filter() {
        return this.value === val;
    });
    return matches;
};

$.fn.valTime = function valTime(val) {
    this.val(profile.use24HourNotation ? to24h(val) : to12h(val));
};

class FormElementFactory {
    constructor(ElementClass, selector, ignoreSelector) {
        this.ElementClass = ElementClass;
        this.selector = selector || ElementClass.selector;
        this.ignoreSelector = ignoreSelector || ElementClass.ignoreSelector;
    }

    createElements(form) {
        const elements = form.formElement.find(this.selector).not(this.ignoreSelector).get();
        return elements.map((element) => new this.ElementClass($(element)), this);
    }
}

class FormElement {
    constructor(element) {
        this.init(element);
        this.name = this.resolveName();
        this.topRight = this.createTopRightBox();
    }

    static createFactory(selector, ignoreSelector) {
        return new FormElementFactory(this, selector, ignoreSelector);
    }
}

export class FormField extends FormElement {
    static selector = ':input';

    // Ignore inputs without ID's.
    static ignoreSelector = '*:not([id]), button';

    init(element) {
        this.input = element;
        this.container = element.closest('li');

        // Shortcut for getting and setting the value.
        this.val = $.fn.val.bind(this.input);
    }

    resolveName() {
        if (this.input.prop('name')) {
            return this.input.prop('name');
        }
        if (this.input.prop('id')) {
            // Strip `id_` part of element id.
            const id = this.input.prop('id');
            return id.replace(/^id_/gi, '');
        }
        throw new Error('Could not resolve name of form field.');
    }

    createTopRightBox() {
        // Create the top right box that contains the override checkbox and info icon.
        const element = $('<div class="top-right">');
        this.container.append(element);
        return element;
    }

    onValueChange(func) {
        this.input.change(() => {
            func(this.val());
        });
        return this;
    }
}

export class FormFieldGroup extends FormElement {
    static selector = '> div';

    static ignoreSelector = '.form-actions, .form-errors';

    init(element) {
        this.container = element;
    }

    resolveName() {
        if (this.container.prop('id')) {
            return this.container.prop('id');
        }
        throw new Error('Could not resolve name of form field group.');
    }

    createTopRightBox() {
        // Create the top right box that contains the override checkbox and info icon.
        const element = $('<div class="top-right">');
        this.container.find('> :header').append(element);
        return element;
    }
}

export class FormFieldSubGroup extends FormFieldGroup {
    static selector = '> div > div:has(h3)';

    static ignoreSelector = '';
}

/**
 * Initializes a Form object.
 *
 * @param {JQuery} element - The form element from this form.
 * @param {FormElementFactory[]} selectors - Optional array of FormElementFactory instances.
 */
export class Form {
    constructor(element, selectors) {
        function createDefaultSelectorSet() {
            return [FormField.createFactory()];
        }
        this.formElement = element;
        this.elementSelectors = selectors || createDefaultSelectorSet();
        this.elements = [];
        this.createElements();
    }

    createElements() {
        this.elementSelectors.forEach((selector) => {
            Array.prototype.push.apply(this.elements, selector.createElements(this));
        }, this);
    }

    /**
     * @param {FormElement} type - Optional type to be filtered on.
     */
    getElements(type) {
        if (!type) {
            // We do not have to filter, so just return everything.
            return this.elements;
        }
        return this.elements.filter((element) => element instanceof type);
    }

    /**
     * @param {string} name - Optional type to be filtered on.
     * @param {FormElement} type - Optional type to be filtered on.
     */
    getFormElement(name, type) {
        const groupPrefix = '__group__';
        if (name.startsWith(groupPrefix)) {
            return this.getFormElement(name.replace(groupPrefix, ''), FormFieldGroup);
        }
        return this.getElements(type).find((element) => element.name === name);
    }

    getField(name) {
        const field = this.getFormElement(name, FormField);

        if (!field) {
            throw Error(`Field ${name} not found.`);
        }
        return field;
    }
}

export class ProjectOverrides {
    constructor(form, overriddenFields, project, multiFields) {
        this.form = form;
        // These are the current fields that are overloaded according to the database.
        this.overriddenFields = overriddenFields;
        this.ignoreFields = [];
        this.multiFields = multiFields || [];
        this.project_loaded = false;
        if (project) {
            this.updateProjectData(project);
        }
        this.override_warning = true;
    }

    getFields(selector = '*') {
        function isNotIgnoredAndSelect(field) {
            return !testSome(this.ignoreFields, field.name) && field.input.is(selector);
        }
        return this.form.getElements(FormField).filter(isNotIgnoredAndSelect, this);
    }

    emitProjectUpdatedEvent() {
        window.dispatchEvent(new Event('projectUpdated'));
    }

    override() {
        // Override fields and create checkboxes and warning popups.
        if (this.project_loaded) {
            this.undo();
        }
        this.overrideFields(this.project);
        this.applyProjectSettingOptions(this.project);
        this.applyPopUps();
        this.applyCheckboxes();
        this.project_loaded = true;
        // Set the project name so that selenium tests know when the override is done.
        window.project_name = this.project.name;
        this.emitProjectUpdatedEvent();
    }

    undo() {
        // Undo the project overrides. Go back to a clean form.
        this.removeProjectSettingOptions();
        this.removeCheckboxes();
        this.removePopUps();
        this.project_loaded = false;
    }

    applyCheckboxes() {
        // Create checkboxes that will disable the fields.
        this.getFields("input:not([name*='_time_'], [type='submit']), textarea:visible").forEach(
            (field) => {
                this.createCheckbox(field);
                field.input
                    .wrap(
                        $("<div class='project-override-container'></div>")
                            .hover(
                                () => {
                                    field.checkbox[0].tippy.show();
                                },
                                () => {
                                    field.checkbox[0].tippy.hide();
                                }
                            )
                            .click(() => {
                                if (!field.checkbox[0].checked) {
                                    if (this.override_warning) {
                                        if (
                                            confirm(
                                                gettext(
                                                    'Are you sure you want to override the project settings?'
                                                )
                                            )
                                        ) {
                                            this.override_warning = false;
                                        } else {
                                            return;
                                        }
                                    }
                                    field.checkbox[0].click();
                                }
                            })
                    )
                    .after($(`<span class='project-override'></span>`));
                field.checkbox.on('click', this.toggleField.bind(this, field));
                this.toggleField(field);
            }
        );

        this.getFields('textarea:hidden').forEach((field) => {
            this.createCheckbox(field);
            field.container
                .find("input:not([type='checkbox'])")
                .wrap(
                    $("<div class='project-override-container'></div>").hover(
                        () => {
                            field.checkbox[0].tippy.show();
                        },
                        () => {
                            field.checkbox[0].tippy.hide();
                        }
                    )
                )
                .after($(`<span class='project-override'></span>`));
            field.checkbox.on('click', this.toggleField.bind(this, field));
            this.toggleField(field);
        });
        // Create invisible checkboxes that change based on selected input.
        this.getFields('select').forEach((field) => {
            this.createCheckbox(field);
            field.checkbox.hide();
            field.container.find('select').change((input) => {
                field.checkbox.prop(
                    'checked',
                    !$(input.target)
                        .find(':selected')
                        .text()
                        .endsWith(` - ${gettext('project setting')}`)
                );
            });
        });
    }

    applyPopUps() {
        this.getFields('select').forEach(this.createPopUp, this);
    }

    removePopUps() {
        this.getFields('select').forEach(this.removePopUp, this);
    }

    overrideFields(data) {
        this.getFields().forEach((field) => {
            if (!this.overriddenFields.includes(field.name)) {
                field.input.val(data[field.name]);
                field.input.trigger('change');
            }
        });
        for (const field of this.multiFields) {
            field.refresh(false);
        }
    }

    updateProjectData(data) {
        this.getFields().forEach((field) => {
            if (!(field.name in data) || data[field.name] === null) {
                data[field.name] = null;
            } else if (field.name === 'alarm_recipients') {
                data[field.name] = JSON.stringify(data[field.name]);
            } else if (data[field.name] === false) {
                data[field.name] = 'False';
            } else if (data[field.name] === true) {
                data[field.name] = 'True';
            } else {
                data[field.name] = String(data[field.name]);
            }
        });
        for (let i = 0; i < 7; i++) {
            if (
                data[`enable_time_${i}`] === '00:00:00' &&
                data[`disable_time_${i}`] === '23:59:00'
            ) {
                data[`enabled_day_${i}`] = '1';
            } else if (
                data[`enable_time_${i}`] === '00:00:00' &&
                data[`disable_time_${i}`] === '00:00:00'
            ) {
                data[`enabled_day_${i}`] = '2';
            } else {
                data[`enabled_day_${i}`] = '3';
            }
        }
        this.project = data;
    }

    createCheckbox(field) {
        const checkId = `override-${field.input.prop('id')}`;
        const checked = this.overriddenFields.includes(field.name);

        // Add the checkbox to the fields.
        const checkbox = $("<input type='checkbox' class='project-override'>");
        checkbox.prop('name', checkId);
        checkbox.prop('id', checkId);
        checkbox.prop('checked', checked);
        this.createTippy(checkbox, gettext('Check to override project settings.'), '');
        checkbox.hover(
            () => {
                checkbox[0].tippy.show();
            },
            () => {
                checkbox[0].tippy.hide();
            }
        );

        field.topRight.append(checkbox);
        field.checkbox = checkbox;
        field.container.data('checkbox', checkbox);
    }

    createPopUp(field) {
        this.createTippy(
            field.container,
            gettext('Note: changing this value will override the project settings.'),
            'focusin'
        );
    }

    createTippy(field, text, trigger) {
        field.each((index, element) => {
            element.tippy = tippy(element, {
                content: text,
                popperOptions: {
                    modifiers: {
                        name: 'preventOverflow',
                    },
                },
                trigger,
                theme: 'omnidots-white-shadow',
                allowHTML: true,
            });
        });
    }

    removePopUp(field) {
        field.container.each((index, element) => {
            element.tippy.destroy();
        });
    }

    applyProjectSettingOptions(project) {
        this.getFields().forEach((field) => {
            if ($(field.container).is(':visible')) {
                const selectBox = field.container.find('select');
                const originalValue = selectBox.val();
                const selected = !this.overriddenFields.includes(field.name);
                const originalOption = field.container
                    .find('select')
                    .not('.project-override')
                    .children('option')
                    .filter(function () {
                        return $(this).val() === project[field.name];
                    });

                if (originalOption.length) {
                    const projectOption = originalOption
                        .clone()
                        .appendTo(selectBox)
                        .text(`${originalOption.text()} - ${gettext('project setting')}`)
                        // Needed for schedule selection.
                        .prop('selected', selected);

                    // Add the `publicName` property that the config helper uses to distinguish this option.
                    // Unfortunately we need to add it manually as jQuery `clone` does not copy custom properties.
                    const publicName = originalOption.prop('publicName');
                    if (!isUndefined(publicName)) {
                        projectOption.prop('publicName', publicName);
                    }
                }

                if (!selected) {
                    // Without this settings can drop to the first entry in the selection.
                    selectBox.val(originalValue);
                }
            }
        });
    }

    removeProjectSettingOptions() {
        $(`option:contains(" - ${gettext('project setting')}")`).remove();
    }

    toggleField(field) {
        this.multiFields.forEach((multiField) => {
            if (field.input.get(0) === multiField.textarea.get(0)) {
                multiField.refresh(!field.checkbox.prop('checked'));
            }
        });

        field.container
            .find('input, textarea, select')
            .not('.project-override')
            .prop('disabled', !field.checkbox.prop('checked'));
        if (!field.checkbox.prop('checked')) {
            field.input.val(this.project[field.name]);
        }
    }

    enableFields(field) {
        field.container
            .find('input, textarea, select')
            .not('.project-override')
            .prop('disabled', false);
    }

    removeCheckboxes() {
        this.getFields().forEach((field) => {
            field.checkbox && field.checkbox.remove();
            this.enableFields(field);
        }, this);
        $('span.project-override').remove();
    }
}

export class MeasuringPointProjectOverrides extends ProjectOverrides {
    constructor(form, overriddenFields, editPage) {
        super(form, overriddenFields, null, [editPage.alarmReceptionFields]);

        this.ignoreFields = [/^name$/, /^sensor_name$/, /^project$/, /^location$/];
        this.scheduleField = editPage.scheduleField;
        this.project_banner = $($('#project_banner_template').html());
        this.form.getFormElement('swarm_settings').container.prepend(this.project_banner);
        this.scheduleField.change_schedules();
    }

    override(data) {
        this.updateProjectData(data);
        super.override();
        // Make project adjusted settings readonly.
        for (let i = 0; i < 7; i++) {
            if (
                $(
                    `#id_enabled_day_${i} option:selected:contains(" - ${gettext(
                        'project setting'
                    )}")`
                ).length
            ) {
                $(`#id_enable_time_${i}, #id_disable_time_${i}`).prop('readonly', true);
            }
        }
        this.project_banner.toggle(true);
        this.overriddenFields = [];
    }

    undo(data) {
        super.undo();
        if (data) {
            this.overrideFields(data);
        }
        this.project_banner.toggle(false);
    }

    overrideFields(data) {
        super.overrideFields(data);
        this.scheduleField.change_schedules();
        for (let i = 0; i < 7; i++) {
            $(`#id_enabled_day_${i}`).change(function change() {
                if ($(this).find(`:selected:contains(" - ${gettext('project setting')}")`).length) {
                    [`enable_time_${i}`, `disable_time_${i}`].forEach((xable) => {
                        $(`#id_${xable}`).prop('readonly', true).valTime(data[xable].slice(0, -3));
                    });
                }
            });
        }
    }

    toggleField(field) {
        super.toggleField(field);

        // In case of the schedule form we toggle the entire row
        // so that changing the times becomes available.
        if (field.name.includes('enabled_day_')) {
            const num = field.name[field.name.length - 1];
            ['enable_time_', 'disable_time_'].forEach((schedule) => {
                const xableField = this.form.getField(`${schedule}${num}`);

                xableField.container
                    .find('input, textarea, select')
                    .not('.project-override')
                    .prop('disabled', !field.checkbox.prop('checked'));

                if (!field.checkbox.prop('checked')) {
                    xableField.input.valTime(this.project[xableField.name]);
                    this.scheduleField.change_schedules();
                }
            });
        }
    }

    createCheckbox(field) {
        super.createCheckbox(field);
        if (field.name.includes('enabled_day_')) {
            const checked = this.overriddenFields.includes(field.name);
            field.checkbox.prop('checked', checked);
        }
    }
}

export class FormInformation {
    constructor(form, information) {
        this.form = form;
        this.information = information;
        this.iconHtml = '<i class="fa fa-info-circle info-icon"></i>';
    }

    applyInfoIcons() {
        Object.entries(this.information).forEach(([name, information]) => {
            const object = this.form.getFormElement(name);
            if (!object) {
                throw new Error(`Field with name '${name}' not found.`);
            }
            const icon = $(this.iconHtml);
            object.tippy = tippy(icon.get(0), {
                onShow(instance) {
                    instance.setContent(applyNamingConvention(information));
                },
                popperOptions: {
                    modifiers: {
                        name: 'preventOverflow',
                    },
                },
                theme: 'omnidots-white-shadow',
                allowHTML: true,
            });
            object.topRight.append(icon);
        });
    }
}

export class ManualHelper {
    constructor(form) {
        this.form = form;
    }

    /**
     * Creates a viewport element overlaying the given form element name.
     *
     * @param {string} name - Name of the FormElement to be used for the viewport.
     */
    setScreenshotViewport(name) {
        if (this.screenshotViewport) {
            this.screenshotViewport.remove();
        }
        const element = this.form.getFormElement(name);
        if (!element) {
            throw new Error(`No form element named ${name}.`);
        }
        const margin = 20;
        const cooridinates = element.container[0].getBoundingClientRect();
        let top = element.container.offset().top - margin;
        let left;
        switch (element.constructor) {
            case FormField:
                left = cooridinates.left - margin;
                break;
            case FormFieldGroup:
                top =
                    element.container.offset().top +
                    parseInt(element.container.css('padding-top'), 10) -
                    margin;

                {
                    let calc = `${cooridinates.left}px - ${margin}px`;

                    // Stupid hack to make this particular screenshot work.
                    if (element.name !== 'measurement_schedule') {
                        calc += ' + 9999rem';
                    }

                    left = `calc(${calc})`;
                }
                break;
            case FormFieldSubGroup:
                left = element.container.offset().left - margin;
                break;
            default:
                throw Error(
                    `Cannot calculate screenshot viewport for ${element.constructor.name}.`
                );
        }

        let height = element.container.height() + margin * 2;
        const maxHeight = 400;
        if (height > maxHeight) {
            height = maxHeight;
        }

        const html = $('<div>');
        html.prop('id', 'screenshot_viewport');
        html.css({
            position: 'absolute',
            height,
            width: element.container.width() + margin * 2,
            top,
            left,
        });
        $('body').append(html);

        // Get screenshot viewport visible in browsers view-port.
        html[0].scrollIntoView({ block: 'start' });
        this.screenshotViewport = html;
    }

    /**
     * Adds 600 pixels of whitespace to the bottom of the page.
     * This is needed for Chrome Headless to make screen-shots
     * of elements close to the bottom.
     */
    applyScreenshotFix() {
        this.form.formElement.append($('<div>').css({ height: '600px' }));
    }
}

function getTypedValue(value) {
    if (value === null) {
        return null;
    }

    // Convert boolean string to a real boolean value.
    if (value === 'False' || value === 'True') {
        return value === 'True';
    }

    // Otherwise parse the option as an int.
    const int = parseInt(value, 10);

    return Number.isNaN(int) ? value : int;
}

// Shows or hides field groups depending on the number of visible
// fields in that group.
function toggleFieldGroups() {
    $('[id^=swarm_settings]').each((_index, element) => {
        const group = $(element);
        const hasVisibleFields = !!group.find('li:not([style*="display: none"])').length;

        group.toggle(hasVisibleFields);
    });
}

function toggleOption(item, show) {
    // Options require a bit more work to make them show/hide then what jQuery does for us
    item.toggle(show);
    if (show) {
        if (item.parent('span.toggleOption').length) item.unwrap();
    } else if (item.parent('span.toggleOption').length == 0) {
        item.wrap('<span class="toggleOption" style="display: none;" />');
    }
}

// NOTE: Yes, this is written with the use of jQuery. That's mainly because I wanted
// to reuse already proven functions for toggling or wrapping fields and options. Since
// this is legacy code, I did not want to put too much time into this except for it to
// make use of the new config helper until we completely switched over to a React form.
export function useConfigHelper(configHelper) {
    const fieldsToWatch = ['deviceName', ...configHelper.allFields];
    const configConverter = new ConfigConverter();
    const form = $('form.form');
    const $sensorName = $('#id_sensor_name');
    const $swarmType = $('#id_swarm_type');

    // Manually hide the SWARM type field for the legacy form.
    $swarmType.closest('li').toggle(false);

    // Collect all the relevant fields.
    const fields = Array.from(configConverter.internalToPublicMap.get('names'))
        // We only want the fields that matter to the config helper.
        .map(([internalName, publicName]) => {
            if (!fieldsToWatch.includes(publicName)) {
                return;
            }

            const $el = form.find(`[name=${internalName}]`);

            const internalToPublicOptions = configConverter.internalToPublicMap
                .get('options')
                .get(internalName);

            $el.find('option').each(function () {
                const value = getTypedValue(this.value);
                this.publicName = internalToPublicOptions
                    ? internalToPublicOptions.get(value)
                    : value;
            });

            return {
                internalName,
                publicName,
                $el,
            };
        })
        // Filter out the undefined's.
        .filter(identity);

    function configChanged() {
        const values = {
            deviceName: $sensorName.val(),
            ...Object.fromEntries(
                fields.map(({ $el }) => [$el.prop('name'), getTypedValue($el.val())])
            ),
        };
        configHelper.updateValues(configConverter.internalToPublic(values));
    }

    const configHelperDone$ = new Subject();

    // Start reacting to config helper updates.
    zip([configHelper.availableFields$, configHelper.currentlySelectedOptions$]).subscribe(
        ([availableFields, currentlySelectedOptions]) => {
            fields.forEach((field) => {
                const availableOptions = availableFields[field.publicName];
                const currentlySelectedOption = currentlySelectedOptions[field.publicName];
                const show = !!availableOptions;

                field.$el.closest('li').toggle(show);

                if (!show) {
                    // Nothing to do here anymore.
                    return;
                }

                field.$el.find('option').each(function () {
                    // Show valid options.
                    toggleOption(
                        $(this),
                        availableOptions === ANY || availableOptions.includes(this.publicName)
                    );

                    // Sometimes the config helper detects that a previous selected option went
                    // unavailable, here we select the next best available option.
                    if (currentlySelectedOption && currentlySelectedOption === this.publicName) {
                        this.selected = true;
                    }
                });
            });

            configHelperDone$.next();

            // Hide to show field groups that do or do not contain fields anymore.
            toggleFieldGroups();
        }
    );

    // Start listening to field updates and run the config helper.
    [$sensorName, ...fields.map((field) => field.$el)].forEach(($el) => {
        $el.on('change propertychange', configChanged);
    });

    // Call me on start too, but after a delay as the selects are being wrapped.
    setTimeout(configChanged, 10);

    return configHelperDone$;
}

export const isEmailValid = (email) => /^.+@.+(?:\.[a-zA-Z]+)*?$/.test(email);

function setError(field, error) {
    if (error) {
        field.addClass('-error');
    } else {
        field.removeClass('-error');
    }
    return error;
}

export function isPhoneNumberValid(v) {
    function handleInvalidPhonenumber(p) {
        if (!NON_CONFORMING_COUNTRIES) {
            return false;
        }

        const countryCallingCode = parseInt(p.countryCallingCode, 10);

        return NON_CONFORMING_COUNTRIES.indexOf(countryCallingCode) > -1 && p.isPossible();
    }

    try {
        const phonenumber = parsePhoneNumber(v);
        const isEmail = v.includes('@');
        const isPhoneNumber = phonenumber.isValid() || handleInvalidPhonenumber(phonenumber);
        return !isEmail && isPhoneNumber;
    } catch (error) {
        if (error instanceof ParseError) {
            return false;
        }

        throw error;
    }
}

export class MultiField {
    constructor(selector, placeholder, validators, container, limit, rowclass) {
        this.textarea = $(selector);
        this.placeholder = placeholder || gettext('E-mail address');
        this.validators = validators || [isEmailValid];
        this.container = container || $("<table id='multi-field-table'></table>");
        this.textarea.after(this.container);
        this.limit = limit;
        this.rowclass = rowclass || 'row';
        this.rows = [];
        this.fields = [];

        this.addRowButton = $(
            "<a class='add-row'><i class='fa fa-plus-circle' aria-hidden='true'></i></a>"
        );
        this.container.after(this.addRowButton);
        this.addRowButton.click(this.addRow.bind(this));
    }

    create() {
        $('form.form').submit(this.beforeSubmit.bind(this));
        this.textarea.hide();

        this.refresh(false);
    }

    // Refresh the fields to match the textarea.
    refresh(validate = true) {
        // Bring this field (and it's subfields) back to their initial state.
        // It also removes the fields that are more than the first 3.
        this.clearAll();

        const values = this.fieldValues();

        // After we just removed the fields more then 3, we now want to add the
        // missing rows that are needed to fill the items from the textarea + 1
        // empty row.
        times(values.length + 1 - this.rows.length).forEach(this.addRow.bind(this));

        this.fillInFields(values);
        if (validate) {
            this.validateFields();
        }
    }

    fieldValues() {
        return this.textarea
            .val()
            .split(/[\n,;]/)
            .filter((v) => !!v);
    }

    addRow() {
        // Add a row, useful if we want to add things next to a field.
        if (this.limit && this.rows.length >= this.limit) {
            return;
        }
        const row = $(`<tr class='${this.rowclass}'></tr>`);

        this.addField(row);
        this.rows.push(row);
        this.container.append(row);
    }

    addField(row) {
        // Add a text field to the row.
        const field = $(
            `<input type='text' placeholder='${this.placeholder} ${this.rows.length + 1}'>`
        );

        field.bind('input propertychange', this.addRowIfLastIsNotEmptyAndValid.bind(this));
        field.bind('input propertychange', this.validateFields.bind(this));

        this.fields.push(field);
        row.append(field);
    }

    fillInFields(values) {
        for (let i = 0; i < values.length; i++) {
            this.fields[i].val(values[i]);
        }
    }

    removeAddedElements(elements) {
        elements.splice(3).forEach((el) => el.remove());
    }

    clearTextFields() {
        this.fields.forEach((field) => field.val(null));
    }

    clearAll() {
        this.removeAddedElements(this.rows);
        this.removeAddedElements(this.fields);
        this.clearTextFields();
    }

    addRowIfLastIsNotEmptyAndValid() {
        const fieldValue = this.fields[this.fields.length - 1].val();
        if (fieldValue && this.is_field_valid(fieldValue)) {
            this.addRow();
        }
    }

    is_field_valid(fieldValue) {
        if (fieldValue) {
            let fieldValid = false;
            for (let j = 0; j < this.validators.length; j++) {
                if (this.validators[j](fieldValue)) {
                    fieldValid = true;
                    break;
                }
            }
            return fieldValid;
        }
        return true;
    }

    validateFields() {
        let isInvalid = false;

        for (let i = 0; i < this.fields.length; i++) {
            const field = this.fields[i];
            isInvalid |= setError(field, !this.is_field_valid(field.val()));
        }
        setError(this.textarea.parent(), isInvalid);

        this.setTextArea();

        return !isInvalid;
    }

    setTextArea() {
        this.textarea.val(
            this.fields
                .map((el) => el.val())
                .filter((v) => !!v)
                .join('\n')
        );
    }

    beforeSubmit(e) {
        if (!this.validateFields()) {
            e.preventDefault();
        }
    }
}

export const createAlarmRecipientPlaceholder = () =>
    `${gettext('E-mail address')} ${gettext('OR')} ${lcFirst(gettext('Phone number'))}`;

export class AlarmReceptionField extends MultiField {
    constructor(selector) {
        const placeholder_text = createAlarmRecipientPlaceholder();
        const container = $("<table id='alarm_settings'></table>");
        const limit = 50;
        const validators = [isEmailValid, isPhoneNumberValid];
        const rowclass = 'alarms';
        super(selector, placeholder_text, validators, container, limit, rowclass);
        this.table_header = $('<tr></tr>');
        this.checkboxes = [];
        this.rows = [];
    }

    create() {
        $.each([1, 2, 3], (i, data) => {
            this.checkboxes.push([
                `alarm_level_${data}`,
                $(`#id_alarm_level_${data}`).parent().find('label').text().replace(':', ''),
            ]);
        });
        this.checkboxes.push(['measuring_point_administrator', gettext('SWARM administrator')]);

        this.table_header.append($('<th></th>'));

        $.each(this.checkboxes, (i, data) => {
            const field = data[0];
            const text = data[1];
            this.table_header.append(
                $(`<th class='rotate'><div><span id='span_${field}'>${text}</span></div></th>`)
            );
            $(`#id_${field}_name`).bind('input propertychange', function () {
                $(`#span_${field}`).text($(this).val() ? $(this).val() : text);
            });
        });
        this.container.append(this.table_header);

        // Update header after adding it.
        $('#alarm_levels input[id^="id_alarm_level_"][id$="_name"]').trigger('propertychange');

        super.create();
    }

    addField(row) {
        const fieldTd = $(`<td class='small-input'></td>`);
        row.append(fieldTd);
        super.addField(fieldTd);

        for (let i = 0; i < this.checkboxes.length; i++) {
            const checkbox = $(
                `<td class='checkbox'><input data='${this.checkboxes[i][0]}' type='checkbox'/></td>`
            );
            checkbox.on('change', this.validateFields.bind(this));
            row.append(checkbox);
        }
    }

    checkCheckbox(recipient, checkbox) {
        this.rows.forEach((row) => {
            if (row.find("input[type='text']").valFilter(recipient).first().length) {
                row.find(`input[data="${checkbox}"]`).prop('checked', true);
            }
        });
    }

    setTextArea() {
        const data = [];

        this.rows.forEach((row) => {
            row.find("input[type='checkbox']:checked").each((index, checkbox) => {
                const recipient = row.find("input[type='text']").val();
                if (isPhoneNumberValid(recipient)) {
                    data.push([
                        'phone_number',
                        parsePhoneNumber(recipient).number,
                        $(checkbox).attr('data'),
                    ]);
                } else if (isEmailValid(recipient)) {
                    data.push(['email', recipient, $(checkbox).attr('data')]);
                }
            });
        });
        this.textarea.val(JSON.stringify(data).replace(/,/g, ', '));
    }

    clearCheckBoxes() {
        this.container.find('input:checkbox').prop('checked', false);
    }

    clearAll() {
        super.clearAll();
        this.clearCheckBoxes();
    }

    any_checkbox_checked_in_row(row) {
        return !!row.find("input[type='checkbox']:checked").length;
    }

    validateFields() {
        let isValid = true;

        for (let i = 0; i < this.fields.length; i++) {
            if (this.fields[i].val()) {
                isValid &= ~setError(this.rows[i], !this.any_checkbox_checked_in_row(this.rows[i]));
            }
        }

        isValid = super.validateFields() && isValid;

        return isValid;
    }

    rowValues() {
        return JSON.parse(this.textarea.val() || '[]') || [];
    }

    fieldValues() {
        const rowValues = this.rowValues();

        const fieldValues = [];
        for (const value of rowValues) {
            if (fieldValues.findIndex((x) => x == value[1]) === -1) {
                fieldValues.push(value[1]);
            }
        }
        return fieldValues;
    }

    fillInFields(values) {
        super.fillInFields(values);
        const rowValues = this.rowValues();

        for (let i = 0; i < rowValues.length; i++) {
            this.checkCheckbox(rowValues[i][1], rowValues[i][2]);
        }
        $.each(this.checkboxes, (i, data) => {
            $(`#id_${data[0]}_name`).trigger('keyup');
        });
    }
}

function getScheduleFields(obj, offset = 0) {
    // Pass a schedule form field object and returns the related fields.
    const num = (parseInt(obj.id[obj.id.length - 1], 10) + offset) % 7;

    const enabledDay = $(`#id_enabled_day_${num}`);
    const enableTime = $(`#id_enable_time_${num}`);
    const disableTime = $(`#id_disable_time_${num}`);

    return { enabledDay, enableTime, disableTime };
}

function setScheduleFields(scheduleFields, enableTimeValue, disableTimeValue) {
    const enabledDay = scheduleFields.enabledDay.val();
    const allOrNothing = enabledDay == '1' || enabledDay == '2';

    scheduleFields.enableTime.prop('readonly', allOrNothing);
    scheduleFields.disableTime.prop('readonly', allOrNothing);

    scheduleFields.enableTime.valTime(enableTimeValue);
    scheduleFields.disableTime.valTime(disableTimeValue);
}

export class ScheduleField {
    constructor() {
        this.create();
    }

    create() {
        $('div[id="measurement_schedule"]')
            .children('li')
            .wrapAll("<div class='form-schedule'></div>");

        const weekdays = [
            gettext('Sunday'),
            gettext('Monday'),
            gettext('Tuesday'),
            gettext('Wednesday'),
            gettext('Thursday'),
            gettext('Friday'),
            gettext('Saturday'),
        ];

        for (let i = 6; i >= 0; i--) {
            if (!$(`#id_enabled_day_${i}`).length) {
                $(`label[for="id_enable_time_${i}"]`)
                    .parent()
                    .before(
                        '<li class="schedule-name">' +
                            `<label for="id_enabled_day_${i}">${weekdays[i]}</label>` +
                            `<select id="id_enabled_day_${i}">` +
                            `<option value="1">${gettext('MEASURE_ALL_DAY')}</option>` +
                            `<option value="2">${gettext('MEASURE_NEVER')}</option>` +
                            `<option value="3">${gettext('MEASURE_CUSTOM')}</option>` +
                            '</select>' +
                            '</li>'
                    );

                if (i > 0) {
                    $(`#id_disable_time_${i}`).after(
                        `<a class="schedule-copy-row" id="id_schedule_copy_${i}">` +
                            `<img src="${arrowBentDownImg}">` +
                            '</a>'
                    );

                    $(`#id_schedule_copy_${i}`).on('click', function () {
                        const scheduleFields = getScheduleFields(this);
                        const nextScheduleFields = getScheduleFields(this, 1);

                        nextScheduleFields.enabledDay.val(scheduleFields.enabledDay.val());

                        setScheduleFields(
                            nextScheduleFields,
                            scheduleFields.enableTime.val(),
                            scheduleFields.disableTime.val()
                        );
                    });
                }
            }
            $(`#id_enabled_day_${i}`).on('change propertychange', function () {
                const scheduleFields = getScheduleFields(this);

                if (this.value == '1') {
                    setScheduleFields(scheduleFields, '00:00', '23:59');
                } else if (this.value == '2') {
                    setScheduleFields(scheduleFields, '00:00', '00:00');
                } else {
                    setScheduleFields(scheduleFields, '08:00', '18:00');
                }
            });
        }
        $('form.form').submit(() => {
            for (let i = 0; i <= 6; i++) {
                const enableField = $(`#id_enable_time_${i}`);
                const disableField = $(`#id_disable_time_${i}`);

                enableField.val(to24h(enableField.val()));
                disableField.val(to24h(disableField.val()));
            }
        });
    }

    change_schedules() {
        const isMidnight = (v) => v === '0:00' || v === '00:00';
        const isMinuteBeforeMidnight = (v) => v === '23:59';
        const isEnabledAllDay = (a, b) => isMidnight(a) && isMinuteBeforeMidnight(b);
        const isDisabledAllDay = (a, b) => isMidnight(a) && isMidnight(b);

        for (let i = 6; i >= 0; i--) {
            const enableTimeInput = $(`#id_enable_time_${i}`);
            const disabledTimeInput = $(`#id_disable_time_${i}`);

            // Let seconds die! Couldn't find a Django fix :sob:.
            [enableTimeInput, disabledTimeInput].forEach((elem) => {
                const eventTime = elem.val();
                if (/\d{2}\:\d{2}\:\d{2}/.test(eventTime)) {
                    elem.valTime(eventTime.slice(0, -3));
                }
            });

            // Check if they should be disabled.
            const enableAt = to24h(enableTimeInput.val());
            const disableAt = to24h(disabledTimeInput.val());

            enableTimeInput.add(disabledTimeInput).prop('readonly', true);

            if (isEnabledAllDay(enableAt, disableAt)) {
                $(`#id_enabled_day_${i} option[value=1]`).prop('selected', true);
            } else if (isDisabledAllDay(enableAt, disableAt)) {
                $(`#id_enabled_day_${i} option[value=2]`).prop('selected', true);
            } else {
                enableTimeInput.add(disabledTimeInput).prop('readonly', false);
                $(`#id_enabled_day_${i} option[value=3]`).prop('selected', true);
            }
        }
    }
}

import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/free-regular-svg-icons';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { getProgressBarColorClass, valueToPercentage } from '../utils';

// Sound limit of 140 dB.
const soundLimit = 140;

const ProgressBar = ({
    alarmLevel,
    warningLevel,
    measuredValue,
    isMinimal,
    unit,
    hasData = true,
}) => {
    const textColorClass = hasData ? 'text-white' : null;
    const conditionalStyles = useMemo(
        () => ({
            progressBarColorClass: hasData
                ? getProgressBarColorClass(measuredValue, warningLevel, alarmLevel)
                : null,
            textColorClass,
        }),
        [hasData, measuredValue, warningLevel, alarmLevel, textColorClass]
    );

    return (
        <>
            <div className="flex h-3.5">
                <div
                    className={`flex w-full max-w-xs justify-between gap-0.5 rounded bg-gray-outline`}
                >
                    <div
                        className={`${conditionalStyles.progressBarColorClass} rounded pl-1 text-2xs ${conditionalStyles.textColorClass}`}
                        style={{ width: `${valueToPercentage(measuredValue, soundLimit)}%` }}
                    >
                        {!isMinimal && (
                            <div className="w-12 text-nowrap">
                                {measuredValue && `${measuredValue} ${unit}`}
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div>
                <div className="mt-[-22px] flex w-full max-w-xs">
                    <div
                        className={`relative flex h-6 border-l border-dashed border-black`}
                        style={{
                            marginLeft: `${valueToPercentage(warningLevel, soundLimit)}%`,
                        }}
                    >
                        <div className={'relative -left-[6px] bottom-3 flex items-center gap-1'}>
                            <FontAwesomeIcon
                                icon={faBell}
                                className="h-2 w-2 rounded-full bg-warning p-0.5 text-white"
                                size="sm"
                            />
                        </div>
                    </div>

                    <div
                        className={`relative flex h-6 border-l border-dashed border-black`}
                        style={{
                            marginLeft: `calc(${valueToPercentage(
                                alarmLevel - warningLevel,
                                soundLimit
                            )}% - 13px)`,
                        }}
                    >
                        <div className={'relative -left-[6px] bottom-3 flex items-center gap-1'}>
                            <FontAwesomeIcon
                                icon={faXmark}
                                className="h-2 w-2 rounded-full bg-error p-0.5 text-white"
                                size="sm"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

ProgressBar.propTypes = {
    hasData: PropTypes.bool,
    alarmLevel: PropTypes.number,
    measuredValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    warningLevel: PropTypes.number,
    isMinimal: PropTypes.bool,
    unit: PropTypes.string,
};

export default ProgressBar;

/* eslint-disable no-underscore-dangle */
import EventEmitter from 'eventemitter3';
import {
    camelCase,
    compact,
    debounce,
    defaultTo,
    find,
    findLast,
    isEmpty,
    isEqual,
    isFunction,
    isNil,
    isNull,
    isUndefined,
    last,
    matches,
    orderBy,
    property,
    snakeCase,
    some,
    sortBy,
    upperFirst,
} from 'lodash-es';
import moment from 'moment';
import 'moment-timezone';
import fetch from 'cross-fetch';
import {
    BehaviorSubject,
    combineLatest,
    firstValueFrom,
    map,
    switchMap,
    filter as rxjsFilter,
    distinctUntilChanged,
    startWith,
    filter,
    skipUntil,
    from,
    of,
    skipWhile,
    skip,
    shareReplay,
    throttleTime,
    pipe,
    delayWhen,
    catchError,
    tap,
    EMPTY,
    defer,
} from 'rxjs';
import { getOperationName } from '@apollo/client/utilities';
import { secondsToMilliseconds } from 'date-fns';
import { configConverter } from '../components/pages/measuringPoint/configConverter';
import schema from '../schema.json';
import warning from '../utils/logger';
import {
    LoadingStateTracker,
    scrollTrigger,
    zoomTrigger,
    yearBack,
    now,
    SlottedQueryManager,
    overrides$,
} from './graph-helpers';
import { getBrowserTimezone } from '../utils/formatting';
import { inRange } from '../utils/range';
import { postData, handleFetchErrors } from './http-helper';
import { getClient } from '../utils/graphql';
import { dustDataTypes } from './graph-elements/dust-provider';
import {
    convertDistance,
    convertDistanceAndFloat,
    dataTypes,
    DataTypes,
    defaultDataType,
    findBestMatchingDataTypeForConfig,
    fixFloat,
    getDataType,
    getDataTypeKey,
    getDataTypeKeyByValue,
    guideLineSettings,
    swarmTypeDataTypes,
} from './data-types';
import {
    dataSetQueries,
    configQuery,
    dustQuery,
    eventsQuery,
    sampleQueries,
    tracesQuery,
    vperPeriodsQuery,
    soundQuery,
    dailyValuesQuery,
} from './queries';
import { SwarmType } from '../enums';
import {
    distinctUntilNotEmptyAnymore,
    fromObservableQuery,
    shareReplayRefCount,
    timeFrames,
} from '../utils/rxjs';
import { PERMISSION } from '../components/pages/sharing/utils';
import { MeasuringPointCommentsCollection } from './graph-elements/comments-plugin/models';
import filterWithinView from './graph-elements/utils/filter-within-date-range';

const { guidelines } = schema.namingConventions;

/**
 * Checks value to determine whether a default value should be returned in
 * its place. The defaultValue is returned if value is null or undefined.
 * A copy of value will be returned if value is not null or undefined.
 *
 * In some cases it is needed to clone the result of the client. In
 * Apollo Client v3 the returned result is read only. They did this because
 * directly mutating the returned result would also mutate the cache and that
 * would often result in buggy behavior. So as of v3 the we need to clone the
 * result before we can mutate it.
 */
const cloneOrDefaultTo = (value, defaultValue) => {
    if (isNil(value)) {
        return defaultValue;
    }

    return value.slice();
};

export const sortRecords = (records) =>
    sortBy(records, (record) =>
        record.__typename === 'SampleBucket'
            ? find(record, 'timestamp').timestamp
            : record.timestamp
    );

const startEventMatcher = matches({ __typename: 'EventStart' });

const loadingStateTracker = new LoadingStateTracker();

function addMsToMinMax(minMax, ms) {
    return minMax.map((timestamp) => timestamp + ms);
}
export class DateRange extends EventEmitter {
    static MODIFIER = {
        ADD: moment.prototype.add,
        SUBTRACT: moment.prototype.subtract,
    };

    static PERIOD = {
        DAY: 'days',
        WEEK: 'weeks',
        MONTH: 'months',
    };

    constructor() {
        super();
        this.min = yearBack.valueOf();
        this.max = now.valueOf();

        this.observable$ = new BehaviorSubject();
        this.updateSubject = () => this.observable$.next([this.min, this.max]);
        this.updateSubject();

        this.timePanningEnabled$ = new BehaviorSubject(false);
        this.timePanningEnabled$
            .pipe(
                switchMap((enabled) => {
                    // If time pan is disabled, do nothing.
                    if (!enabled) {
                        return EMPTY;
                    }

                    // If time pan is enabled, create an observable that emits a value
                    // each second. For each 'frame' or emission, we increase the current
                    // min and max viewing period by the elapsed time.
                    return timeFrames(secondsToMilliseconds(1)).pipe(
                        tap((elapsed) => {
                            this.setMinMax(...addMsToMinMax(this.getMinMax(), elapsed));
                        })
                    );
                })
            )
            .subscribe();
    }

    dispatchUpdated(...args) {
        this.emit('updated', ...args);
    }

    set(values, emitter = null) {
        // Update values if they are provided and not null or undefined.
        ['min', 'max'].forEach((key) => {
            const newValue = values[key];
            if (!isNil(newValue)) {
                this[key] = newValue.valueOf();
            }
        });
        this.dispatchUpdated(emitter);
        this.updateSubject();
    }

    setMinMax(min, max, emitter = null) {
        this.set({ min, max }, emitter);
    }

    getMinMax() {
        return [this.min, this.max];
    }

    applyModifier(momentAddSubtract, period, timezone) {
        this.setMinMax(
            ...this.getMinMax().map((date) =>
                momentAddSubtract.call(moment.tz(date, timezone), 1, period)
            )
        );
    }

    toggleTimePanning() {
        this.timePanningEnabled$.next(!this.timePanningEnabled$.getValue());
    }
}

const camelCaseToEventName = (name) => snakeCase(name).replaceAll('_', '-');

export class BaseDataSet extends EventEmitter {
    static EVENTS = ['updated', 'loading-updated', 'visible-updated'];

    constructor({ dateRange = null } = {}) {
        super();
        this.registerEvents();

        this.graphElementObservables = new Map();

        // Multiple Datasets can share one DateRange. If no DateRange is given, we create our own.
        this.dateRange = dateRange ?? new DateRange();
        this.dateRange.on('updated', this.dispatchVisibleUpdated.bind(this));

        this.eventsLoaded = false;
        this.once('events-updated', () => {
            this.eventsLoaded = true;
        });
    }

    createEventDispatchMethodName(event) {
        return `dispatch${upperFirst(camelCase(event))}`;
    }

    registerEvents() {
        this.constructor.EVENTS.forEach((event) => {
            Object.defineProperty(this, this.createEventDispatchMethodName(event), {
                value: (...args) => this.emit(event, ...args),
            });
        });
    }

    get visibleMin() {
        return this.dateRange.min;
    }

    get visibleMax() {
        return this.dateRange.max;
    }

    setVisibleMinMax(min, max, emitter = null) {
        this.dateRange.set({ min, max }, emitter);
    }

    setVisibleMin(min, emitter = null) {
        this.dateRange.set({ min }, emitter);
    }

    setVisibleMax(max, emitter = null) {
        this.dateRange.set({ max }, emitter);
    }

    getVisibleMinMax() {
        return this.dateRange.getMinMax();
    }

    checkDataUpdateNeeded(forceLoad) {
        // Min and max in milliseconds:
        const min = this.visibleMin;
        const max = this.visibleMax;

        const diff = max - min;
        const loadedDiff = this.loadedMax - this.loadedMin;

        // What is that in value related to diff?
        const scrollTriggerDiff = diff * scrollTrigger;
        const zoomTriggerDiff = diff * zoomTrigger * 4;

        const visibleAndLoadedDiffer = () => {
            // No need to compare things if there is nothing loaded.
            if (isNil(this.loadedMin) || isNil(this.loadedMax)) {
                return true;
            }

            // What is the user doing? Trigger for left, right and in, out.
            const leftTriggered = min - scrollTriggerDiff < this.loadedMin;
            const rightTriggered = max + scrollTriggerDiff > this.loadedMax;
            const zoomInTriggered = loadedDiff > (diff + zoomTriggerDiff) * (zoomTrigger + 1);
            const zoomOutTriggered = loadedDiff < (diff + zoomTriggerDiff) * (1 - zoomTrigger);

            return leftTriggered || rightTriggered || zoomInTriggered || zoomOutTriggered;
        };

        // Update needed?
        if (forceLoad || visibleAndLoadedDiffer()) {
            // Load 2 times the trigger extra.
            const newLoadedMin = Math.floor(min - scrollTriggerDiff * 2);
            const newLoadedMax = Math.ceil(max + scrollTriggerDiff * 2);

            // Retrieve data.
            this.request(newLoadedMin, newLoadedMax, forceLoad);
        }
    }

    filterRecordsWithinView(records) {
        return records.filter((record) => {
            const timestampsInRecord =
                record.__typename === 'SampleBucket'
                    ? compact(Object.values(record).map(property('timestamp')))
                    : [record.timestamp];

            return timestampsInRecord.every((timestamp) =>
                inRange(timestamp, this.visibleMin, this.visibleMax)
            );
        });
    }

    matchesPermission$(permission) {
        return this.permission$.pipe(map((sensorPermission) => sensorPermission === permission));
    }
}

const observables = [
    // `config` holds 3 states: undefined when config is not yet known, null when
    // the config is not available and an object when the config is found.
    // The state difference between not yet known and not available is needed
    // to detect the state change to trigger the selectUsedLine method.
    ['config$', undefined, false],
    ['configs$', [], false],
    ['samples$', [], true],
    ['events$', [], true],
    ['traces$', [], true],
    ['vperPeriods$', [], true],
    ['dustData$', []],
    ['dustMeta$', []],
    ['dustDataAvailable$', []],
    ['soundData$', []],
    ['timezone$', null, true],
    [
        'dataType$',
        (dataSet, incomingDataType) => incomingDataType || defaultDataType[dataSet.swarmType],
        true,
    ],
    ['categoryLine$', null],
    ['categoryLineOverrideError$', false],
    ['categoryLineOverrideInvalid$', false],
    ['categoryLineIsFlat$', false],
    ['alarmLines$', []],
];

const simpleObservablesProxies = [
    'dataTypeSettings$',
    'guideLineSettings$',
    'combinedSettings$',
    'categoryAndAlarmLines$',
    'sensor$',
    'permission$',
    'dailyValues$',
    'dailyValuesLoading$',
    'dailyValuesTriggers$',
    'dailyValuesTimeSettings$',
    'comments$',
    'commentsInView$',
];

const toNonObservableName = (observableName) => observableName.slice(0, -1);

export class DataSet extends BaseDataSet {
    constructor(
        id,
        lineUrl,
        alarmsUrl,
        {
            name = null,
            swarmType = SwarmType.VIBRATION,
            permission = PERMISSION.VIEW,
            dataType = null,
            dateRange = null,
            enableFetchTraces = true,
            isBrushDataSet = false,
            isActive$ = of(true),
            reportMode = false,
        } = {}
    ) {
        super({ dateRange });

        this.lineUrl = lineUrl;
        this.alarmsUrl = alarmsUrl;

        this.sensor = id;
        this.sensor$ = new BehaviorSubject(id);
        this.name = name;
        this.swarmType = swarmType;
        this.permission$ = new BehaviorSubject(permission);
        this.enableFetchTraces = enableFetchTraces;
        this.isBrushDataSet = isBrushDataSet;
        this.isActive$ = isActive$;
        this.reportMode = reportMode;

        if (!dataType) {
            this.needToFindBetterDataType = true;
        }

        this.previousDataType = null;

        observables.forEach(([observableName, initialValue, emitEvents]) => {
            const observable$ = new BehaviorSubject(
                isFunction(initialValue) ? initialValue(this, dataType) : initialValue
            );

            // Strip the $ sign off the observableName to get the event / getter name.
            const propertyName = toNonObservableName(observableName);

            if (emitEvents) {
                observable$.subscribe((...args) =>
                    this.emit(`${camelCaseToEventName(propertyName)}-updated`, ...args)
                );
            }

            Object.defineProperty(this, observableName, {
                // eslint-disable-next-line rxjs/suffix-subjects
                value: observable$,
            });

            // Add a getter. This is legacy while transitioning to RXJS.
            Object.defineProperty(this, propertyName, {
                get: () => observable$.getValue(),
            });
        });

        const alarmLinesUpdateAfterConfigUpdate$ = this.alarmLines$.pipe(
            // Since `alarmLines$` is a behavior subject and emits on subscription,
            // we want to skip all emissions until the first real config comes in.
            // A real config is an emission that holds a value different from `undefined`.
            skipUntil(this.config$.pipe(filter((config) => config !== undefined)))
        );

        this.ready = Promise.all([
            new Promise((resolve) => {
                this.setRequestsHandled = resolve;
            }),
            firstValueFrom(alarmLinesUpdateAfterConfigUpdate$),
        ]);

        this.initCategoryAndAlarmLines();

        this.dataTypeSettings$ = this.dataType$.pipe(
            map((currentDataType) => getDataType(currentDataType)),
            distinctUntilChanged()
        );

        this.guideLineSettings$ = this.config$.pipe(
            map((config) => {
                const guideLine = config?.guideLine;

                if (!guideLine) {
                    return {};
                }

                return guideLineSettings[guidelines[guideLine]] ?? {};
            }),
            distinctUntilChanged()
        );

        this.combinedSettings$ = combineLatest([
            this.dataTypeSettings$,
            this.guideLineSettings$,
        ]).pipe(
            map(([dataTypeSettings, incomingGuideLineSettings]) =>
                // Create a new `DataType` instance from the prototype of `dataTypeSettings`
                // with the properties of `dataTypeSettings` and `incomingGuideLineSettings`.
                Object.assign(
                    Object.create(dataTypeSettings),
                    dataTypeSettings,
                    incomingGuideLineSettings
                )
            )
        );

        this.initTimezoneUpdater();

        // Wrap this class its request method with a debounce.
        this.request = debounce(this.request, 300);

        this.queryManager = new SlottedQueryManager();

        // Wrap functions that do requests with a loading wrapper.
        [
            'fetchConfig',
            'fetchAlarms',
            'fetchDust',
            'fetchSound',
            'fetchEvents',
            'fetchLine',
            'fetchSamples',
            'fetchTraces',
            'fetchVperPeriods',
        ].forEach((fnName) => {
            this[fnName] = loadingStateTracker.wrapFunction(this, this[fnName]);
        });

        // We need to define this `swarmTypeFetchers` inside the constructor instead
        // of directly as class property as otherwise the fetchers are not wrapped
        // with the loadingStateTracker used above.
        this.swarmTypeFetchers = {
            [SwarmType.VIBRATION]: this.fetchSamples,
            [SwarmType.AIR]: this.fetchDust,
            [SwarmType.SOUND]: this.fetchSound,
        };

        if (this.isBrushDataSet) {
            return;
        }

        combineLatest([this.config$, overrides$])
            .pipe(distinctUntilChanged((previous, current) => isEqual(previous, current)))
            .subscribe(([config, overrides]) => {
                // Update the lines.
                this.selectUsedLine(config, overrides);
            });

        this.startEvents$ = this.events$.pipe(map((events) => events.filter(startEventMatcher)));

        this.configsInView$ = this.startEvents$.pipe(
            map((startEvents) => this.filterRecordsWithinView(startEvents).map(property('config')))
        );

        // configsInEvents$ contains configs extracted from the startEvents$.
        this.configsInEvents$ = this.startEvents$.pipe(
            // As `events$` has an initial value that it will emit as soon as we
            // subscribe we want to skip that, we only want to react on real API updates.
            map((startEvents) => startEvents.map(property('config'))),
            shareReplay({ bufferSize: 1 })
        );

        this.configsInEvents$
            .pipe(
                // Skip first initial value, only react on real API updates.
                skip(1),
                switchMap((configsInEvents) =>
                    combineLatest([
                        of(configsInEvents),
                        from(this.switchToBetterDataTypeIfPossible()),
                    ])
                ),
                // `switchToBetterDataTypeIfPossible` will return true when it found a better
                // data type, if it found a better data type we stop propagation as we can
                // expect list of configs soon.
                skipWhile(([_configsInEvents, foundABetterDataType]) => foundABetterDataType),
                // Ditch the `foundABetterDataType`.
                map(([configsInEvents, _]) => configsInEvents),
                // If both 'previous' and 'current' are empty, we infer no changes have occurred.
                // This halts emission, preventing unnecessary unsubscriptions and subscriptions
                // to the 'config$' observable within the subsequent switchMap.
                distinctUntilNotEmptyAnymore(),
                switchMap((configsInEvents) => {
                    // `config$` has the ability to fetch a fallback config from the API if
                    // the configs inside events are empty. We can use that fallback config
                    // for the `configs$` here too.
                    if (isEmpty(configsInEvents)) {
                        return this.config$.pipe(
                            // Wrap the single config in an array so that it looks like an
                            // array of configs.
                            map((config) => (isNil(config) ? [] : [config])),
                            distinctUntilNotEmptyAnymore()
                        );
                    }

                    return of(configsInEvents);
                })
            )
            .subscribe(this.configs$);

        /**
         * Determine new value for `config$` when:
         * * The active data type changes.
         * * New configs are received from the API.
         * * The visible min max gets updated.
         */
        combineLatest([
            this.dataType$,
            // Skip first initial value, only react on real API updates.
            this.configsInEvents$.pipe(skip(1)),
            // Add in `throttleTime` to make sure we have some time to
            // breathe during scrolling and zooming the graph.
            this.dateRange.observable$.pipe(
                throttleTime(300, null, { leading: true, trailing: true }),
                this.delayWhenNotActive()
            ),
        ])
            .pipe(
                switchMap(([incomingDataType, _configsInEvents, _dateRange]) =>
                    from(this.findLastConfig(incomingDataType)).pipe(
                        catchError((error) => {
                            warning(error);
                            return of(null);
                        })
                    )
                ),
                distinctUntilChanged()
            )
            .subscribe(this.config$);

        this.initDailyValues();

        this.initComments();
    }

    createLoadingSideEffect$() {
        return loadingStateTracker.createLoadingSideEffect$(this);
    }

    initDailyValues() {
        const dailyValuesApiResponse$ = defer(() => {
            const queryParameters = {
                variables: { measuringPointId: this.sensor },
                query: dailyValuesQuery,
            };

            return fromObservableQuery(getClient().watchQuery(queryParameters), {
                useInitialLoading: true,
            });
        }).pipe(shareReplayRefCount());

        this.dailyValuesLoading$ = dailyValuesApiResponse$.pipe(
            map(({ loading }) => loading),
            startWith(true)
        );
        this.dailyValues$ = dailyValuesApiResponse$.pipe(
            map(({ data }) => data?.dailyValues.values ?? [])
        );
        this.dailyValuesTriggers$ = dailyValuesApiResponse$.pipe(
            map(({ data }) => {
                // Sort the triggers in descending order.
                const triggers = data?.dailyValues.settings.triggers;

                if (!triggers) {
                    return [];
                }

                return orderBy(triggers, ['trigger'], ['desc']);
            })
        );
        this.dailyValuesTimeSettings$ = dailyValuesApiResponse$.pipe(
            map(({ data }) => data?.dailyValues.settings.timeSettings ?? null)
        );
    }

    // RxJS operator that works as a traffic light.
    // When `this.isActive$ == true`, it will let all traffic trough.
    // When `this.isActive$ == false`, it will stop traffic, then wait until
    // it becomes true, then emits the last value that hit the red traffic light.
    delayWhenNotActive() {
        return pipe(
            delayWhen(() => this.isActive$.pipe(skipWhile((isActive) => isActive === false)))
        );
    }

    // Creates a backup of all query results in this dataset. You can use
    // the resulting backup in Storybook to simulate the graph.
    pullState() {
        const queries = dataSetQueries.map((query) => ({
            queryName: query.definitions[0].selectionSet.selections[0].name.value,
            operationName: getOperationName(query),
            result: getClient().readQuery(
                this.getQueryParameters(this.loadedMin, this.loadedMax, false, query)
            ),
        }));

        return {
            measuringPointId: this.sensor,
            startTime: this.dateRange.min,
            endTime: this.dateRange.max,
            swarmType: this.swarmType,
            dataType: this.dataType,
            queries,
        };
    }

    setLoading(loading) {
        this.loading = loading;

        this.dispatchLoadingUpdated(this.loading);
    }

    getQueryParameters(min, max, force, query) {
        const queryParameters = {
            variables: {
                measuringPointId: this.sensor,
                startTime: min,
                endTime: max,
            },
            query,
        };

        if (force) {
            queryParameters.fetchPolicy = 'network-only';
        }

        return queryParameters;
    }

    async fetchSamples(min, max, force) {
        const response = await this.queryManager.execute(
            this.getQueryParameters(min, max, force, sampleQueries[this.dataType])
        );

        this.samples$.next(defaultTo(response.data.rangedSamples, []));
    }

    async refetch(fetchMethod) {
        if (!this.loadedMin || !this.loadedMax) {
            return;
        }

        await fetchMethod(this.loadedMin, this.loadedMax);
    }

    refetchRequest() {
        this.refetch(this.request.bind(this));
    }

    async fetchVperPeriods(min, max, force) {
        const response = await this.queryManager.execute(
            this.getQueryParameters(min, max, force, vperPeriodsQuery)
        );

        this.vperPeriods$.next(defaultTo(response.data.rangedSamples, []));
    }

    async fetchDust(min, max, force) {
        const response = await this.queryManager.execute(
            this.getQueryParameters(min, max, force, dustQuery)
        );

        this.dustMeta$.next(sortRecords(cloneOrDefaultTo(response.data.dustMeta, [])));

        // `dustData` can contain multiple entries for the same timestamp. So here we:
        // * Group all samples on the same timestamp.
        // * Fill missing dust values between timestamps.
        const lastPmValues = {};
        const dustData = Object.values(
            sortRecords(cloneOrDefaultTo(response.data.dustSamples, [])).reduce((groups, entry) => {
                if (!(entry.timestamp in groups)) {
                    groups[entry.timestamp] = { ...entry };
                }

                dustDataTypes.forEach((type) => {
                    if (entry[type]) {
                        lastPmValues[type] = entry[type];
                    }
                    groups[entry.timestamp][type] = defaultTo(lastPmValues[type], null);
                });

                return groups;
            }, {})
        );

        this.dustData$.next(dustData);
        this.dustDataAvailable$.next(
            dustDataTypes.filter((type) => dustData[0]?.[`containsP${type.slice(1)}`])
        );
    }

    async fetchSound(min, max, force) {
        const response = await this.queryManager.execute(
            this.getQueryParameters(min, max, force, soundQuery)
        );

        this.soundData$.next(response.data.soundSamples);
    }

    async fetchEvents(min, max, force) {
        const response = await this.queryManager.execute(
            this.getQueryParameters(min, max, force, eventsQuery)
        );

        const events = cloneOrDefaultTo(response.data.events, []);

        this.events$.next(sortRecords(events));
    }

    async fetchTraces(min, max, force) {
        const response = await this.queryManager.execute(
            this.getQueryParameters(min, max, force, tracesQuery)
        );

        this.traces$.next(sortRecords(cloneOrDefaultTo(response.data.traces, [])));
    }

    async fetchConfig(dataType = null) {
        const queryParameters = this.getQueryParameters(
            this.visibleMin,
            this.visibleMax,
            false,
            configQuery
        );

        if (dataType) {
            queryParameters.variables.dataTab = getDataTypeKey(dataType);
        }

        const response = await this.queryManager.execute(queryParameters);

        return defaultTo(response.data.config, null);
    }

    async fetchLine(config, record) {
        return postData(this.lineUrl, {
            // Map the 'public' config into an 'internal' config.
            // This is needed until https://github.com/omnidots/website/issues/6689 is addressed.
            config: configConverter.publicToInternal(config),
            record: configConverter.publicToInternal(record, true),
        });
    }

    async fetchAlarms() {
        const response = await fetch(this.alarmsUrl(this.sensor));

        if (!handleFetchErrors(response)) {
            return null;
        }

        return response.json();
    }

    /**
     * Switches to a better matching data type if this data set was
     * configured to do so with `this.needToFindBetterDataType`.
     *
     * @returns {boolean} Returns true if it found and switched to a
     *                    better data type, false if not.
     */
    async switchToBetterDataTypeIfPossible() {
        if (!this.needToFindBetterDataType) {
            return false;
        }

        const lastConfig = await this.findLastConfig();

        // No need to check for a better data type if there is no config.
        if (!lastConfig) {
            return false;
        }

        const dataType = findBestMatchingDataTypeForConfig(lastConfig);

        // Could not find any measured data type in the last config.
        // This is actually really weird.
        if (!dataType) {
            warning('Encountered a config without a measured data type.', {
                measuringPointId: this.sensor,
                sensorPk: lastConfig.sensorPk,
                id: lastConfig.id,
            });
            return false;
        }

        this.needToFindBetterDataType = false;

        // `setDatatype` returns true if the dataType has been changed, returns
        // false if the dataType has not changed (e.g. new datatype is the same
        // as the current one)
        return this.setDatatype(dataType.key);
    }

    initTimezoneUpdater() {
        combineLatest([this.samples$, this.events$])
            .pipe(
                map((recordsArrays) => {
                    const lastRecord = last(
                        recordsArrays
                            // From each array of records get the last item.
                            .map((records) => last(records))
                            // When `records` is empty `last` will return
                            // undefined. We now filter those `undefined`s out.
                            .filter((record) => !isUndefined(record))
                    );

                    if (lastRecord) {
                        let { timezone } = lastRecord;

                        if (!moment.tz.zone(timezone)) {
                            warning(`Timezone '${timezone}' not recognized by Moment.js`);
                            timezone = getBrowserTimezone();
                        }

                        return timezone;
                    }

                    return null;
                }),
                // Do not emit when timezone is `null`.
                rxjsFilter((timezone) => !isNull(timezone)),
                startWith('UTC'),
                // Only emit timezones that are different from the previous.
                distinctUntilChanged()
            )
            .subscribe(this.timezone$);
    }

    async request(min, max, force) {
        this.loadedMin = min;
        this.loadedMax = max;

        const fetchers = [this.fetchEvents];

        if (this.enableFetchTraces) {
            fetchers.push(this.fetchTraces);
        }

        if (this.dataType === DataTypes.VEFF) {
            fetchers.push(this.fetchVperPeriods);
        }

        Object.entries(this.swarmTypeFetchers).forEach(([swarmType, fetcher]) => {
            if (swarmTypeDataTypes[swarmType].includes(this.dataType)) {
                fetchers.push(fetcher);
            }
        });

        const promises = fetchers.map((method) => method.call(this, min, max, force));

        await Promise.all(promises);

        this.dispatchUpdated();
        this.setRequestsHandled();
    }

    /**
     * Finds the last config for the selected data type.
     */
    _findLastConfig(configs, dataType) {
        // Include the appropriate configMatcher if we provided a dataType.
        const configMatcher = dataType ? getDataType(dataType).configMatcher : null;

        // Reverse search for a config.
        const config = findLast(configs, configMatcher);

        return config;
    }

    findLastConfigWithinView(dataType) {
        const lastConfigWithinView$ = this.configsInView$.pipe(
            map((configsInView) => this._findLastConfig(configsInView, dataType))
        );

        return firstValueFrom(lastConfigWithinView$);
    }

    findLastConfigWithinEvents(dataType) {
        const lastConfigWithinEvents$ = this.configsInEvents$.pipe(
            map((configsInEvents) => this._findLastConfig(configsInEvents, dataType))
        );

        return firstValueFrom(lastConfigWithinEvents$);
    }

    async findLastConfig(dataType = null) {
        return (
            // First try to find a config within view.
            (await this.findLastConfigWithinView(dataType)) ||
            // If that doesn't work, we search all loaded configs.
            (await this.findLastConfigWithinEvents(dataType)) ||
            // If that doesn't find the correct config, we want to
            // ask the API for the correct configuration.
            this.fetchConfig(dataType)
        );
    }

    selectUsedLine(config, overrides) {
        const { showGuideLineLines } = this.dataTypeSettings();

        // Don't show a line if this data type does not support it or if we don't have a config.
        if (!showGuideLineLines || !config) {
            this.categoryLine$.next(null);
            this.categoryLineIsFlat$.next(false);
            this.categoryLineOverrideError$.next(false);
            this.categoryLineOverrideInvalid$.next(false);

            this.alarmLines$.next([]);

            this.dispatchUpdated();
            return;
        }

        this.getLine(overrides || {}, config);
    }

    async getLine(config, record) {
        const response = await this.fetchLine(config, record);

        if (isNull(response)) {
            return;
        }

        // Automatic detection failed, adjust the settings.
        this.categoryLineOverrideError$.next(response.error);

        // The data does not match the current settings!
        this.categoryLineOverrideInvalid$.next(!response.valid_override);

        this.categoryLine$.next(response.line);
        this.categoryLineIsFlat$.next(response.is_flat_line);

        this.dispatchUpdated();
        this.getAlarmLevels();
    }

    async getAlarmLevels() {
        const response = await this.fetchAlarms(this.sensor);
        const alarms = response?.alarms;

        this.alarmLines$.next(
            // `alarms` could be `null` when measuring point is
            // configured to hide alarm lines.
            Array.isArray(alarms) && Array.isArray(this.categoryLine)
                ? // Create an array with alarm lines, each alarm line is
                  // the category line times the alarm multiplier.
                  alarms.map((alarmPercentage) => {
                      const multiplier = alarmPercentage / 100;
                      return this.categoryLine.map((point) => [point[0] * multiplier, point[1]]);
                  })
                : []
        );

        this.dispatchUpdated();
    }

    initCategoryAndAlarmLines() {
        this.categoryAndAlarmLines$ = combineLatest([this.categoryLine$, this.alarmLines$]).pipe(
            map(([categoryLine, alarmLines]) => {
                const lines = [];

                if (categoryLine) {
                    lines.push({
                        type: 'catergory',
                        line: categoryLine,
                    });

                    alarmLines.forEach((alarmLine, index) => {
                        lines.push({
                            type: 'alarm',
                            line: alarmLine,
                            alarmIndex: index,
                        });
                    });
                }

                return lines;
            })
        );
    }

    setDatatype(dataType) {
        if (!some(dataTypes, ['key', dataType])) {
            throw new Error(`Unable to set unknown data type '${dataType}'.`);
        }

        if (this.dataType === dataType) {
            // Nothing changed.
            return false;
        }

        this.dataType$.next(dataType);

        // Run the request method again. Maybe the new data type requires an
        // extra query to be executed. Also `request` will call `fetchEvents`
        // (that will hit the cache) and in turn update the config according
        // to the new data type.
        this.refetch(this.request.bind(this));

        return true;
    }

    dataTypeSettings() {
        return getDataType(this.dataType);
    }

    convertDistance(value) {
        return convertDistance(this.dataType, value);
    }

    fixFloat(value) {
        return fixFloat(this.dataType, value);
    }

    convertDistanceAndFloat(value) {
        return convertDistanceAndFloat(this.dataType, value);
    }

    initComments() {
        this.commentCollection = new MeasuringPointCommentsCollection(this);

        // Create a shortcut.
        this.comments$ = this.commentCollection.entries$;

        this.commentsInView$ = combineLatest([
            this.comments$.pipe(filterWithinView(this.dateRange)),
            this.dataType$,
        ]).pipe(
            map(([comments, dataType]) =>
                comments.filter((comment) => comment.dataType === getDataTypeKeyByValue(dataType))
            ),
            // Sort by start date.
            map((comments) => sortBy(comments, [(comment) => comment.start$.getValue()])),
            // Both the actions and renderer are going to use `commentsInView$`, to
            // make things more efficient we use `shareReplay` to share the outcome of
            // above calculations.
            shareReplayRefCount()
        );
    }
}

export class EmptyDataSet extends DataSet {
    request() {
        throw new Error('Tried to request data for an empty data set');
    }
}

const fullYearDateRange = new DateRange();

export class MultiSensorDataSet extends BaseDataSet {
    constructor(lineUrl, alarmsUrl) {
        super({});

        this.lineUrl = lineUrl;
        this.alarmsUrl = alarmsUrl;

        this.loadedMin = null;
        this.loadedMax = null;

        this.currentSensor$ = new BehaviorSubject(null);
        this.sensorDataSets = new Map();
        this.sensorBrushDataSets = new Map();

        this.emptyDataSet = new EmptyDataSet();

        this.brushDataSet = new BaseDataSet();

        this.initProxy();
    }

    get currentSensor() {
        return this.currentSensor$.getValue();
    }

    loadSensor(id, name, swarmType, permission) {
        if (!this.sensorDataSets.has(id)) {
            this.createSensorDataset(id, name, swarmType, permission);
        }

        this.currentSensor$.next(id);

        this.dispatchUpdated();
    }

    createSensorDataset(id, name, swarmType, permission) {
        const args = [id, this.lineUrl, this.alarmsUrl];
        const isActive$ = this.currentSensor$.pipe(map((currentSensor) => currentSensor === id));

        const dataSet = new DataSet(...args, {
            name,
            swarmType,
            permission,
            dateRange: this.dateRange,
            isActive$,
        });
        this.sensorDataSets.set(id, dataSet);

        const brushDataSet = new DataSet(...args, {
            name,
            swarmType,
            permission,
            dateRange: fullYearDateRange,
            isBrushDataSet: true,
        });
        this.sensorBrushDataSets.set(id, brushDataSet);

        // Make the brush data set mirror the normal data set data type.
        dataSet.dataType$.subscribe((dataType) => {
            brushDataSet.setDatatype(dataType);
        });

        dataSet.on('loading-updated', this.setLoading.bind(this));

        // Setup event proxies.
        this.constructor.EVENTS.forEach((event) => {
            // We don't need to proxy this event because the MultiSensorDataset
            // sends it itself as well.
            if (event === 'visible-updated') {
                return;
            }

            const dispatchMethodName = this.createEventDispatchMethodName(event);
            dataSet.on(event, this[dispatchMethodName].bind(this));
        });

        // Send first request to get the brush graph data.
        brushDataSet.request(fullYearDateRange.min, fullYearDateRange.max);
    }

    setLoading(loading) {
        this.loading = loading;
    }

    getDataSet(sensor) {
        return this.sensorDataSets.get(sensor) || this.emptyDataSet;
    }

    getCurrentDataSet() {
        return this.getDataSet(this.currentSensor);
    }

    getBrushDataSet(sensor) {
        return this.sensorBrushDataSets.get(sensor) || this.emptyDataSet;
    }

    getCurrentBrushDataSet() {
        return this.getBrushDataSet(this.currentSensor);
    }

    // Proxy to current sensor dataset.
    initProxy() {
        const properties = [
            'ready',
            'sensor',
            'data',
            'swarmType',
            'setDatatype', // function
            'convertDistance', // function
            'convertDistanceAndFloat', // function
            'dataTypeSettings', // function
            'findLastConfig', // function
        ];

        properties.forEach((prop) => {
            Object.defineProperty(this, prop, {
                get: () => {
                    if (typeof this.getCurrentDataSet()[prop] === 'function') {
                        return (...args) => this.getCurrentDataSet()[prop](...args);
                    }

                    return this.getCurrentDataSet()[prop];
                },
            });
        });

        observables.forEach(([observableName, initialValue, emitEvents]) => {
            const observable$ = new BehaviorSubject(
                isFunction(initialValue) ? initialValue(this, DataTypes.VTOP) : initialValue
            );

            this.currentSensor$
                .pipe(switchMap((currentSensor) => this.getDataSet(currentSensor)[observableName]))
                .subscribe(observable$);

            // Strip the $ sign off the observableName to get the event / getter name.
            const name = toNonObservableName(observableName);

            if (emitEvents) {
                observable$.subscribe((...args) =>
                    this.emit(`${camelCaseToEventName(name)}-updated`, ...args)
                );
            }

            Object.defineProperty(this, observableName, {
                // eslint-disable-next-line rxjs/suffix-subjects
                value: observable$,
            });

            // Add a getter. This is legacy while transitioning to RXJS.
            Object.defineProperty(this, name, {
                get: () => observable$.getValue(),
            });

            // Create brush data set.
            const brushObservable$ = this.currentSensor$.pipe(
                switchMap((currentSensor) => this.getBrushDataSet(currentSensor)[observableName])
            );

            Object.defineProperty(this.brushDataSet, observableName, {
                value: brushObservable$,
            });
        });

        simpleObservablesProxies.forEach((observableName) => {
            Object.defineProperty(this, observableName, {
                // eslint-disable-next-line rxjs/suffix-subjects
                value: this.currentSensor$.pipe(
                    switchMap((currentSensor) => this.getDataSet(currentSensor)[observableName])
                ),
            });
        });
    }

    request(min, max, force) {
        this.loadedMin = min;
        this.loadedMax = max;

        this.getCurrentDataSet().request(this.loadedMin, this.loadedMax, force);
    }
}

import $ from 'jquery';

/**
 * Override jquery >= 3.3.1 append and appendTo functions because of wkhtml2pdf 12.5 issues
 *
 * https://github.com/wkhtmltopdf/wkhtmltopdf/issues/4341
 */

/**
 * .append()
 *
 * $(target).append(contentToBeAppended)
 */
const originalAppend = $.fn.append;
$.fn.append = function append(par) {
    if (arguments.length >= 1 && typeof par === 'object') {
        this[0].appendChild(par[0]);
        return this;
    }
    return originalAppend.call(this, par);
};

/**
 * .appendTo()
 *
 * $(contentToBeAppended).appendTo(jqTarget)
 */
const originalAppendTo = $.fn.appendTo;
$.fn.appendTo = function appendTo(par) {
    if (arguments.length >= 1 && typeof par === 'object') {
        par[0].appendChild(this[0]);
        return this;
    }
    return originalAppendTo.call(this, par);
};

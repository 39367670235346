import { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useObservableEagerState, useObservableState } from 'observable-hooks';
import { isEmpty } from 'lodash-es';
import { BehaviorSubject } from 'rxjs';
import { DAILY_VALUE_BG_COLORS, dataShape } from './utils';
import { BaseDataSet } from '../../graph/dataset';
import SkeletonLoader from '../skeletonLoader/SkeletonLoader';

const triggerBadgeClasses = 'gap-1 rounded border border-menu-color px-1 py-px mr-1';

function ExceedanceBlock({ color, trigger }) {
    return (
        <div
            className={`relative -left-2.5 -mr-2.5 w-2 ${color} bg-gradient-to-r from-white`}
            data-testid={`${trigger}-exceeded`}
        ></div>
    );
}
ExceedanceBlock.propTypes = {
    color: PropTypes.string,
    trigger: PropTypes.number,
};

function BudgetItem({ item, trigger, hasExceeded, mapColor, highlighted$ }) {
    const highlighted = useObservableState(highlighted$);

    return (
        <div
            className={`relative h-3 flex-1 ${mapColor(item.lXEq)} ${
                highlighted?.date === item.date ? 'ring-2 ring-black' : ''
            } ${hasExceeded ? '' : 'first:rounded-s'} last:rounded-e`}
            onMouseEnter={() => {
                highlighted$.next(item);
            }}
            onMouseLeave={() => {
                highlighted$.next(null);
            }}
            data-testid={`budget-${trigger}-${item.date}`}
        ></div>
    );
}
BudgetItem.propTypes = {
    item: dataShape,
    trigger: PropTypes.number.isRequired,
    hasExceeded: PropTypes.bool.isRequired,
    mapColor: PropTypes.func.isRequired,
    highlighted$: PropTypes.instanceOf(BehaviorSubject).isRequired,
};

function BudgetRow({ color, days, trigger, data, mapColor, highlighted$ }) {
    const hasExceeded = data.length > days;
    const underLimit = days - data.length;

    return (
        <>
            <div className="mt-3 flex w-full max-w-full items-center justify-between">
                <div className="flex items-center">
                    <span className={`mr-1 h-3 w-3 rounded-sm ${color}`}></span>
                    &ge; {trigger}dB(A)
                </div>
                <div className="flex items-center text-menu-color">
                    {data.length === days && (
                        <div className={triggerBadgeClasses}>{gettext('ON_LIMIT')}</div>
                    )}
                    {hasExceeded && (
                        <div className={triggerBadgeClasses}>{gettext('EXCEEDED')}</div>
                    )}
                    {data.length}/{days}
                </div>
            </div>
            <div className="mt-2 flex w-full max-w-full gap-0.5 rounded">
                {hasExceeded && <ExceedanceBlock color={color} trigger={trigger} />}
                {data.map((item, i) =>
                    i < days ? (
                        <BudgetItem
                            key={item.date}
                            item={item}
                            trigger={trigger}
                            hasExceeded={hasExceeded}
                            highlighted$={highlighted$}
                            mapColor={mapColor}
                        />
                    ) : null
                )}
                {/* Fill in empty data spaces. */}
                {underLimit > 0 &&
                    Array.from({ length: underLimit }, (v, i) => (
                        <div
                            key={i}
                            data-testid={`${trigger}-emptyline-${i}`}
                            className={`h-3 flex-1 bg-menu-hover last:rounded-e`}
                        ></div>
                    ))}
            </div>
        </>
    );
}
BudgetRow.propTypes = {
    color: PropTypes.string.isRequired,
    days: PropTypes.number.isRequired,
    trigger: PropTypes.number.isRequired,
    data: PropTypes.array.isRequired,
    mapColor: PropTypes.func.isRequired,
    highlighted$: PropTypes.instanceOf(BehaviorSubject).isRequired,
};

export function BudgetViewer({ dataSet, highlighted$ }) {
    const dailyValuesLoading = useObservableEagerState(dataSet.dailyValuesLoading$);
    const values = useObservableEagerState(dataSet.dailyValues$);
    const triggers = useObservableEagerState(dataSet.dailyValuesTriggers$);

    // Sort data based on triggers.
    const sortedData = useMemo(
        () =>
            triggers.reduce((acc, item) => {
                const filteredValues = values.filter((value) => value.lXEq >= item.trigger);
                acc[item.trigger] = filteredValues;
                return acc;
            }, {}),
        [triggers, values]
    );

    // Return color based on number value.
    const mapColor = useCallback(
        (number) => {
            const colorIndex = triggers.findIndex((item) => number >= item.trigger);
            return DAILY_VALUE_BG_COLORS?.[colorIndex] ?? 'bg-menu-hover';
        },
        [triggers]
    );

    if (dailyValuesLoading) {
        return <SkeletonLoader count={3} wrapperClassName="p-4" />;
    }

    if (isEmpty(values)) {
        return <p className="text-center">{gettext('No data found')}</p>;
    }

    return (
        <div className="text-xs font-semibold">
            {triggers.map((value, index) => (
                <BudgetRow
                    color={DAILY_VALUE_BG_COLORS[index]}
                    days={value.days}
                    trigger={value.trigger}
                    key={index}
                    data={sortedData[value.trigger]}
                    mapColor={mapColor}
                    highlighted$={highlighted$}
                />
            ))}
        </div>
    );
}
BudgetViewer.propTypes = {
    dataSet: PropTypes.instanceOf(BaseDataSet).isRequired,
    highlighted$: PropTypes.instanceOf(BehaviorSubject).isRequired,
};

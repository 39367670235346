import { gql } from '@apollo/client';
import { isObject, lowerFirst, memoize, repeat, upperFirst } from 'lodash-es';

import { useMemo } from 'react';
import { ExtendableError } from '../../../utils/errors';
import { getClient } from '../../../utils/graphql';
import memoizeMultiArg from '../../../utils/memoizeMultiArg';

import { SwarmType, SwarmTypeTranslations } from '../../../enums';
import { getFormSchema } from './schema-utils';

class SwarmTypeError extends ExtendableError {}

class ConfigTypeInfo {
    constructor(type, objectName, translation) {
        this.type = type;
        this.objectName = objectName;
        this.translation = translation;
    }

    // The name of the config type when used in GraphQL enums.
    type = '';

    // The name that is being used inside GraphQL queries.
    objectName = '';

    // The translated version.
    translation = '';
}

/**
 * @type {Object.<string, ConfigTypeInfo>}
 */
export const ConfigType = Object.freeze({
    PROJECT: new ConfigTypeInfo('PROJECT', 'Project', gettext('PROJECT')),
    MEASURING_POINT: new ConfigTypeInfo(
        'MEASURING_POINT',
        'MeasuringPoint',
        gettext('MEASURING_POINT')
    ),
});

/**
 * Returns a project or measuring point `ConfigTypeInfo` instance
 * depending on the `isProject` parameter.
 * @param {boolean} isProject boolean
 * @returns {ConfigTypeInfo}
 */
export const getConfigType = (isProject) =>
    isProject ? ConfigType.PROJECT : ConfigType.MEASURING_POINT;

const swarmTypeToUpperFirst = (swarmType) => upperFirst(swarmType);

export const getSwarmTypeByGraphqlObject = (object) => {
    const typeName = object?.__typename;

    const foundKey = Object.keys(SwarmType).find((key) =>
        typeName.startsWith(upperFirst(key.toLowerCase()))
    );

    if (!Object.keys(SwarmType).includes(foundKey)) {
        throw new SwarmTypeError(
            `Could not find swarm type for GraphQL object with type name: ${typeName}`
        );
    }

    return SwarmType[foundKey];
};

const indent = repeat(' ', 4);

const createIndents = (indentLevel) => repeat(indent, indentLevel);

export const convertFieldsObjectIntoGqlFieldsString = (fields, indentLevel = 0) =>
    Object.entries(fields)
        .map(([key, value]) => {
            const indents = createIndents(indentLevel);
            const subFields =
                isObject(value) &&
                ` {\n${convertFieldsObjectIntoGqlFieldsString(
                    value,
                    indentLevel + 1
                )}\n${indents}}`;

            return `${indents}${key}${subFields || ''}`;
        })
        .join('\n');

const MUTATION_ERRORS = `
    errors {
        field
        message
    }
`;

const createFragment = (name, type, fields) => gql`
        fragment ${name} on ${type} {
            ${fields}
        }
    `;

const createQuery = (resolver, fragment, fragmentName) => gql`
    query ${resolver} ($id: ID!) {
        ${resolver}(id: $id) {
            ...${fragmentName}
        }
    }
    ${fragment}
`;

export const createMutation = (mutation, inputType, returnKey, fragment, fragmentName) => gql`
    mutation($input: ${inputType}!) {
        ${mutation}(input: $input) {
            ${returnKey} {
                ...${fragmentName}
            }
            ${MUTATION_ERRORS}
        }
    }
    ${fragment}
`;

const createCRUQueries = ({
    fragmentName,
    fragmentType,
    fragmentFields,

    getResolverName,

    updateMutationName,
    updateMutationInput,
    updateMutationReturnKey,

    createMutationName,
    createMutationInput,
    createMutationReturnKey,
}) => {
    const FRAGMENT = createFragment(fragmentName, fragmentType, fragmentFields);
    return {
        returnFieldsFragment: FRAGMENT,
        returnFieldsFragmentName: fragmentName,
        GET: createQuery(getResolverName, FRAGMENT, fragmentName),
        getResolverName,
        UPDATE: createMutation(
            updateMutationName,
            updateMutationInput,
            updateMutationReturnKey,
            FRAGMENT,
            fragmentName
        ),
        updateMutationName,
        CREATE: createMutation(
            createMutationName,
            createMutationInput,
            createMutationReturnKey,
            FRAGMENT,
            fragmentName
        ),
        createMutationName,
    };
};

export const createObjectNames = (swarmType, isProject) => {
    const objectName = `${swarmTypeToUpperFirst(swarmType)}${getConfigType(isProject).objectName}`;

    return {
        objectName,
        objectNameLowerFirst: lowerFirst(objectName),
    };
};

export const createListQueryForVariant = memoizeMultiArg((swarmType, isProject) => {
    const { objectNameLowerFirst } = createObjectNames(swarmType, isProject);
    const resolverName = `${objectNameLowerFirst}s`;
    return [
        gql`
            query get${upperFirst(resolverName)} {
                ${resolverName} {
                    id
                    name
                }
            }
        `,
        resolverName,
    ];
});

const createCRUQueriesForVariant = (swarmType, isProject) => {
    const { returnFields } = getFormSchema(swarmType, isProject);

    const { objectName, objectNameLowerFirst } = createObjectNames(swarmType, isProject);

    const fragmentName = `${objectName}Fields`;
    const fragmentFields = convertFieldsObjectIntoGqlFieldsString(returnFields);

    return {
        objectName: objectNameLowerFirst,
        ...createCRUQueries({
            fragmentName,
            fragmentType: `${objectName}Type`,
            fragmentFields,

            getResolverName: `${objectNameLowerFirst}ById`,
            getAllResolverName: `${objectNameLowerFirst}s`,

            createMutationName: `${objectNameLowerFirst}Create`,
            createMutationInput: `${objectName}CreateInput`,
            createMutationReturnKey: `${objectNameLowerFirst}`,

            updateMutationName: `${objectNameLowerFirst}Update`,
            updateMutationInput: `${objectName}UpdateInput`,
            updateMutationReturnKey: `${objectNameLowerFirst}`,
        }),
    };
};

export const createQueriesForSwarmType = memoize((swarmType) => {
    const MP = createCRUQueriesForVariant(swarmType, false);
    const PROJECT = createCRUQueriesForVariant(swarmType, true);
    return {
        // isProject: false.
        false: {
            objectName: MP.objectName,
            returnFieldsFragment: MP.returnFieldsFragment,
            returnFieldsFragmentName: MP.returnFieldsFragmentName,
            getEntityQuery: MP.GET,
            getResolverName: MP.getResolverName,
            updateEntityQuery: MP.UPDATE,
            updateEntityQueryName: MP.updateMutationName,
            createEntityQuery: MP.CREATE,
            createEntityQueryName: MP.createMutationName,
            getParentEntityQuery: PROJECT.GET,
            getParentResolverName: PROJECT.getResolverName,
            parentEnityFieldName: 'project',
        },
        // isProject: true.
        true: {
            objectName: PROJECT.objectName,
            returnFieldsFragment: PROJECT.returnFieldsFragment,
            returnFieldsFragmentName: PROJECT.returnFieldsFragmentName,
            getEntityQuery: PROJECT.GET,
            getResolverName: PROJECT.getResolverName,
            updateEntityQuery: PROJECT.UPDATE,
            updateEntityQueryName: PROJECT.updateMutationName,
            createEntityQuery: PROJECT.CREATE,
            createEntityQueryName: PROJECT.createMutationName,
            getParentEntityQuery: null,
            getParentResolverName: null,
            parentEnityFieldName: null,
        },
    };
});

const GET_MEASURING_POINT_OR_PROJECT_TYPE_BY_ID = gql`
    query GetEntityTypeById($id: ID!, $isProject: Boolean!) {
        measuringPointById(id: $id) @skip(if: $isProject) {
            ... on MeasuringPointInterface {
                name
            }
            __typename
        }
        projectById(id: $id) @include(if: $isProject) {
            ... on ProjectInterface {
                name
            }
            __typename
        }
    }
`;

export const getSwarmInfoById = async (id, isProject) => {
    const { data } = await getClient().query({
        query: GET_MEASURING_POINT_OR_PROJECT_TYPE_BY_ID,
        variables: {
            id,
            isProject,
        },
    });

    const returnedObject = isProject ? data?.projectById : data?.measuringPointById;

    // Return null if the measuring point or project was not found.
    if (!returnedObject) {
        return null;
    }

    return returnedObject;
};

export const getSwarmTypeById = async (id, isProject) => {
    const returnedObject = await getSwarmInfoById(id, isProject);

    // Return null if the measuring point or project was not found.
    if (!returnedObject) {
        return null;
    }

    return getSwarmTypeByGraphqlObject(returnedObject);
};

export const usePageTitle = (swarmType, isProject, isEdit) =>
    useMemo(() => {
        const configType = getConfigType(isProject);
        const baseText = isEdit ? gettext('PAGE_TITLE_EDIT') : gettext('PAGE_TITLE_CREATE');

        return interpolate(
            baseText,
            {
                swarmType: SwarmTypeTranslations[swarmType],
                configType: configType.translation,
            },
            true
        );
    }, [swarmType, isProject, isEdit]);

export function isInBeta(swarmType) {
    return swarmType === SwarmType.SOUND;
}

import {
    addHours,
    isWithinInterval,
    subMilliseconds,
    startOfDay,
    eachMinuteOfInterval,
} from 'date-fns';
import { utcToZonedTime, formatInTimeZone, toDate, format } from 'date-fns-tz';
import PropTypes from 'prop-types';
import useEventListener from '@use-it/event-listener';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { scaleTime } from 'd3-scale';
import { range } from 'lodash-es';
import { computePosition, shift } from '@floating-ui/core';
import { platform as platformDom } from '@floating-ui/dom';
import { useLocale } from '../../utils/date-fns';
import SelectionBlock from './selectionBlock/SelectionBlock';
import SkeletonLoader from '../skeletonLoader/SkeletonLoader';
import { format as formatDate } from '../../utils/formatting';
import TimeIndicator from './timeIndicator/TimeIndicator';

function getDayPeriod(locale, period) {
    return locale.localize.dayPeriod(period);
}

const isoFormatWithoutZ = "yyyy-MM-dd'T'HH:mm:ss.SSS";

const calendarTimezone = 'Etc/UTC';

// The value '160' is gotten arbitrarily after manual testing,
// to determine which point is best to show the minimal progress bar
// and enlarged selection block on hover.
const minimalSelectionWidth = 160;

// Function that converts a date to a calendar date in the UTC timezone.
// This ensures that dates are standardized by removing any timezone offset.
// For example:
// 2024-03-31 00:00:00+01:00 becomes 2024-03-31 00:00:00+00:00
// 2024-03-31 23:59:59+02:00 becomes 2024-03-31 23:59:59+00:00
// This helps in eliminating issues with variable-length days (e.g., 23 or 25 hours)
// caused by daylight saving time changes, ensuring consistent formatting.
function toCalendarDate(date) {
    return toDate(format(date, isoFormatWithoutZ), { timeZone: calendarTimezone });
}

function SelectionWrapper({ block, formatTime, unit, id }) {
    const [isHovered, setIsHovered] = useState(false);
    const isMinimal = block.width < minimalSelectionWidth;
    const elementRef = useRef();

    const [overriddenLeft, setOverriddenLeft] = useState();

    useEffect(() => {
        if (!(isHovered && isMinimal)) {
            setOverriddenLeft(null);
            return;
        }

        const element = elementRef.current;
        const parent = element.parentElement;

        const canvas = {
            x: 0,
            y: 0,
            ...platformDom.getDimensions(parent),
        };

        // The `reference` contains initial width and left
        // position of the block.
        const reference = {
            width: block.width,
            height: 0,
            x: block.left,
            y: 0,
        };

        // `floating` is the Selection Block.
        const floating = {
            x: 0,
            y: 0,
            ...platformDom.getDimensions(element),
        };

        const platform = {
            getElementRects: (data) => data,
            getDimensions: platformDom.getDimensions,
            getClippingRect: () => canvas,
        };

        computePosition(reference, floating, {
            platform,
            middleware: [shift()],
        }).then(({ x }) => {
            setOverriddenLeft(x);
        });
    }, [isHovered, block, isMinimal]);

    return (
        <div
            className="absolute hover:z-10"
            style={{
                left: overriddenLeft || block.left,
                width: isMinimal && isHovered ? 270 : block.width,
            }}
            onMouseOver={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            data-testid={id}
            ref={elementRef}
        >
            <SelectionBlock
                {...{
                    name: block.period.timeWindow.name,
                    startTime: formatTime(block.period.timeSpan.from),
                    endTime: formatTime(block.period.timeSpan.to),
                    warningLevel: block.period.timeWindow.warningLevel,
                    value: block.period.value,
                    alarmLevel: block.period.timeWindow.alarmLevel,
                    correction: block.period.timeWindow.correction,
                    isMinimal: isMinimal && !isHovered,
                    unit,
                }}
            />
        </div>
    );
}
SelectionWrapper.propTypes = {
    block: PropTypes.object.isRequired,
    formatTime: PropTypes.func.isRequired,
    unit: PropTypes.string,
    id: PropTypes.string.isRequired,
};

function LdenCalendar({
    data,
    inViewStartDate,
    dayStartOffset,
    timezone,
    unit,
    loading,
    currentDate,
}) {
    const locale = useLocale();

    const graphGridRef = useRef();
    const graphGridWidthRef = useRef();

    const formatTime = useCallback((date) => formatInTimeZone(date, timezone, 'HH:mm'), [timezone]);
    const currentDateTimeZoned = useMemo(
        () => utcToZonedTime(currentDate, timezone),
        [currentDate, timezone]
    );

    const startOfWeek = useMemo(() => {
        // Step 1: Convert the inViewStartDate (local browser date) to the
        // specified timezone (e.g., 'Europe/Amsterdam').
        const inViewStartDateZoned = utcToZonedTime(inViewStartDate, timezone);

        // Step 2: Get the start of the day and add our 'start of the day offset' to it.
        const startOfWeekZoned = addHours(startOfDay(inViewStartDateZoned), dayStartOffset);

        // Step 3: Convert the zoned start of the week to UTC.
        return toCalendarDate(startOfWeekZoned);
    }, [inViewStartDate, timezone, dayStartOffset]);

    const days = useMemo(
        () =>
            range(7).map((day) => {
                // We cannot use `addDays` as that does not necessarily move the date 24 hours
                // when moving over DST changes.
                const start = addHours(startOfWeek, day * 24);
                const end = subMilliseconds(addHours(start, 24), 1);

                return {
                    start,
                    end,
                    // Creates the name of the day in the locale provided.
                    name: formatInTimeZone(start, calendarTimezone, 'cccc', { locale }),
                    // Creates a unique key that looks like `2022-365` that can be used in React.
                    key: formatInTimeZone(start, calendarTimezone, 'yyyy_D', {
                        useAdditionalDayOfYearTokens: true,
                    }),
                };
            }),
        [startOfWeek, locale]
    );

    // Create time columns by dividing the first day in 'days' into 60-minute intervals
    // and formatting each interval's hour in the calendar timezone.
    const timeColumns = useMemo(
        () =>
            eachMinuteOfInterval(
                {
                    start: days[0].start,
                    end: days[0].end,
                },
                { step: 60 }
            ).map((d) => formatInTimeZone(d, calendarTimezone, 'HHmm')),
        [days]
    );

    const [blocksPerDay, setBlocksPerDay] = useState(new Map());
    const [timeIndicatorPerDay, setTimeIndicatorPerDay] = useState(new Map());

    const calculateBlocks = useCallback(() => {
        if (!graphGridRef.current) {
            setBlocksPerDay(new Map());
            return;
        }

        const { width } = graphGridRef.current.getBoundingClientRect();

        // Store the used width to prevent unnecessary recalculation triggered
        // by the resize event while the width stays the same.
        graphGridWidthRef.current = width;

        const scalesPerDay = new Map(
            days.map((day) => [day, scaleTime().domain([day.start, day.end]).range([0, width])])
        );

        const perDay = new Map(days.map((day) => [day, []]));
        const currentTimePerDay = new Map(
            days.map((day) => [
                day,
                isWithinInterval(currentDateTimeZoned, { start: day.start, end: day.end })
                    ? scalesPerDay.get(day)(toCalendarDate(currentDateTimeZoned))
                    : null,
            ])
        );

        // Separate the periods that could span multiple days into to be rendered blocks per day.
        data.forEach((period) => {
            let startDay = null;

            const fromInCalendar = toCalendarDate(utcToZonedTime(period.timeSpan.from, timezone));
            const toInCalendar = toCalendarDate(utcToZonedTime(period.timeSpan.to, timezone));

            days.forEach((day) => {
                let startsOnThisDay = false;
                let endsOnThisDay = false;

                if (isWithinInterval(fromInCalendar, day)) {
                    startDay = day;
                    startsOnThisDay = true;
                }

                if (isWithinInterval(subMilliseconds(toInCalendar, 1), day)) {
                    endsOnThisDay = true;
                }

                if (startDay) {
                    const start = startsOnThisDay ? fromInCalendar : day.start;
                    const end = endsOnThisDay ? toInCalendar : day.end;

                    const left = scalesPerDay.get(day)(start);
                    const right = scalesPerDay.get(day)(end);

                    perDay.get(day).push({ period, left, width: right - left });
                }

                if (endsOnThisDay) {
                    // If the period ended on this day, we also want to reset our
                    // startDay to prevent creating blocks for the days after this day.
                    startDay = null;
                }
            });
        });
        setTimeIndicatorPerDay(currentTimePerDay);
        setBlocksPerDay(perDay);
    }, [currentDateTimeZoned, data, graphGridRef, days, timezone]);

    useEffect(() => {
        calculateBlocks();
    }, [calculateBlocks]);

    useEventListener('resize', () => {
        const { width } = graphGridRef.current.getBoundingClientRect();

        if (width !== graphGridWidthRef.current) {
            calculateBlocks();
        }
    });

    const timePeriods = useMemo(
        () => [gettext('DAY'), getDayPeriod(locale, 'evening'), getDayPeriod(locale, 'night')],
        [locale]
    );

    return (
        <div className="flex py-8 text-[9px]">
            <div className="mt-2.5 w-24 font-bold">
                {days.map(({ name, start }, index) => (
                    <div key={index} className="h-20 pb-10 pt-8 text-xs uppercase">
                        {name}
                        <div className="text-2xs text-menu-color">
                            {formatDate(start, { timezone: calendarTimezone, hideTime: true })}
                        </div>
                    </div>
                ))}
            </div>
            <div className="w-full">
                <div className="relative -top-8 flex justify-around">
                    {timePeriods.map((time, index) => (
                        <div key={index} className="grow text-center uppercase">
                            {time}
                        </div>
                    ))}
                </div>
                <div className="relative -left-2.5 -mt-7 flex">
                    {timeColumns.map((time) => (
                        <div key={time} className="grow">
                            {time}
                        </div>
                    ))}
                </div>
                <div ref={graphGridRef} className="mt-1">
                    <div className="relative flex">
                        {Array.from({ length: 24 }).map((_, index) => (
                            <div
                                key={index}
                                className="h-2 grow border-x-[0.5px] border-light-gray px-3"
                            ></div>
                        ))}
                    </div>
                    {days.map((day) => (
                        <div key={day.key} className="relative flex">
                            {loading ? (
                                <SkeletonLoader
                                    wrapperClassName={'h-20 flex-1 p-1'}
                                    className={'h-full p-1'}
                                />
                            ) : (
                                <>
                                    {timeColumns.map((time) => (
                                        <div
                                            key={time}
                                            id={`${day.key}_${time}`}
                                            className="h-20 grow border-[0.5px] border-light-gray bg-gray-200 px-3"
                                        ></div>
                                    ))}

                                    {blocksPerDay.get(day)?.map((block) => {
                                        const id = `${block.period.timeWindow.startDay}_${block.period.timeWindow.name}`;
                                        return (
                                            <SelectionWrapper
                                                block={block}
                                                formatTime={formatTime}
                                                unit={unit}
                                                key={id}
                                                id={id}
                                            />
                                        );
                                    })}
                                    {timeIndicatorPerDay.get(day) && (
                                        <div
                                            className="absolute z-20"
                                            style={{ left: timeIndicatorPerDay.get(day) }}
                                            key={timeIndicatorPerDay.get(day)}
                                        >
                                            <TimeIndicator />
                                        </div>
                                    )}
                                </>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

LdenCalendar.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    inViewStartDate: PropTypes.object.isRequired,
    dayStartOffset: PropTypes.number.isRequired,
    timezone: PropTypes.string.isRequired,
    unit: PropTypes.string,
    currentDate: PropTypes.instanceOf(Date).isRequired,
    loading: PropTypes.bool,
};

export default LdenCalendar;

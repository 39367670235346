import { isNull } from 'lodash-es';
import { faCheck, faXmark, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';

export function getProgressBarColorClass(measuredValue, warningLevel, alarmLevel) {
    const offset = warningLevel - measuredValue;
    if (isNull(offset)) return null;

    if (offset > 0) return 'bg-success';
    if (measuredValue >= alarmLevel) return 'bg-error';

    return 'bg-warning';
}

export function valueToPercentage(value, max) {
    const percentage = Math.round((value / max) * 100);

    return percentage > 100 ? 100 : percentage;
}

export function getLdenIcon(measuredValue, warningLevel, alarmLevel) {
    if (measuredValue < warningLevel) return { icon: faCheck, iconColor: 'text-success' };
    if (measuredValue > alarmLevel) return { icon: faXmark, iconColor: 'text-error' };
    return { icon: faTriangleExclamation, iconColor: 'text-warning' };
}

import ReactDOM from 'react-dom';
import TopBanner from './components/topBanner/TopBanner';
import profile from './profile';

const topBannerModule = ({ options: { elementId } }) => {
    const url = `https://www.omnidots.com/${
        profile.languageCode === 'de' ? 'de' : 'en'
    }/request-free-intergeo-ticket`;

    ReactDOM.render(
        <TopBanner
            text={gettext('EXHIBIT_STUTTGART')}
            buttonText={gettext('GET_FREE_TICKET')}
            buttonUrl={url}
            cookieName="hideBanner"
        />,
        document.getElementById(elementId)
    );
};

export default topBannerModule;

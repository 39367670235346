import { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { DateRange } from 'react-date-range';
import { startOfISOWeek, endOfISOWeek, isDate } from 'date-fns';
import { addWeeks } from 'date-fns/fp';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { format } from '../../../utils/formatting';
import Tooltip from '../../../utils/tooltip';

export function applyModifierForTimezone(date, timezone, modifier) {
    const zonedDate = utcToZonedTime(date, timezone);

    // Step 2: Get the start of the day in the specified timezone.
    const zonedWithModifier = modifier(zonedDate);

    // Step 3: Convert the zoned start of day to a UTC-equivalent timestamp.
    return zonedTimeToUtc(zonedWithModifier, timezone);
}

export function getThisWeekForTimezone(timezone) {
    return Object.freeze({
        startDate: applyModifierForTimezone(new Date(), timezone, startOfISOWeek),
        endDate: applyModifierForTimezone(new Date(), timezone, endOfISOWeek),
        key: 'selection',
    });
}

export function useThisWeekForTimezone(timezone) {
    return useState(getThisWeekForTimezone(timezone));
}

function DateButton({ icon, onClick }) {
    return (
        <button onClick={onClick}>
            <FontAwesomeIcon icon={icon} className="text-dark-gray" size="lg" />
        </button>
    );
}
DateButton.propTypes = {
    icon: PropTypes.object.isRequired,
    onClick: PropTypes.func.isRequired,
};

function getWeekRangeForDate(date) {
    return { key: 'selection', startDate: startOfISOWeek(date), endDate: endOfISOWeek(date) };
}

function applyModifierToRange(range, modifier) {
    return {
        ...range,
        startDate: modifier(range.startDate),
        endDate: modifier(range.endDate),
    };
}

export function WeekPicker({ range: rangeZoned, onChange, timezone }) {
    const [visible, setVisible] = useState(false);
    const show = () => setVisible(true);
    const hide = () => setVisible(false);

    const applyModifier = useCallback(
        (modifier) => onChange((range) => applyModifierToRange(range, modifier)),
        [onChange]
    );

    // Convert local browser time to sensor zoned time.
    const onChangeWithTimezone = useCallback(
        (newRange) => onChange(applyModifierToRange(newRange, (d) => zonedTimeToUtc(d, timezone))),
        [onChange, timezone]
    );

    // Convert the sensor zoned time range back to local browser time.
    const range = useMemo(
        () => applyModifierToRange(rangeZoned, (d) => utcToZonedTime(d, timezone)),
        [rangeZoned, timezone]
    );

    const rangeText = useMemo(
        () =>
            [range.startDate, '-', range.endDate]
                .map((p) =>
                    isDate(p) ? format(p, { useBrowserTimezone: true, hideTime: true }) : p
                )
                .join(' '),
        [range]
    );

    const handleChange = useCallback(
        ({ selection }) => onChangeWithTimezone(getWeekRangeForDate(selection.startDate)),
        [onChangeWithTimezone]
    );

    const handleCurrentWeekButton = useCallback(
        () => onChange(getThisWeekForTimezone(timezone)),
        [timezone, onChange]
    );

    return (
        <Tooltip
            visible={visible}
            onClickOutside={hide}
            interactive={true}
            content={
                <DateRange
                    weekStartsOn={1}
                    onChange={(item) => handleChange(item)}
                    ranges={[range]}
                    // Keeping the selection at range 0 step 0 prevents the range
                    // picker from entering the end date selection mode.
                    focusedRange={[0, 0]}
                    preventSnapRefocus={true}
                    rangeColors={['#F8DA28']}
                />
            }
        >
            <div className="flex items-center gap-2 text-xs font-bold text-dark-gray">
                <button
                    className="cursor-pointer border border-disabled-data-type-button px-1 py-0.5"
                    onClick={handleCurrentWeekButton}
                >
                    {gettext('THIS_WEEK')}
                </button>
                <div className="flex w-48 justify-evenly rounded border text-center">
                    <DateButton icon={faChevronLeft} onClick={() => applyModifier(addWeeks(-1))} />
                    <button
                        className="border-x border-disabled-data-type-button px-1 py-0.5"
                        onClick={visible ? hide : show}
                    >
                        <span>{rangeText}</span>
                    </button>
                    <DateButton icon={faChevronRight} onClick={() => applyModifier(addWeeks(1))} />
                </div>
            </div>
        </Tooltip>
    );
}
WeekPicker.propTypes = {
    range: PropTypes.shape({
        startDate: PropTypes.instanceOf(Date).isRequired,
        endDate: PropTypes.instanceOf(Date).isRequired,
    }),
    onChange: PropTypes.func.isRequired,
    timezone: PropTypes.string.isRequired,
};

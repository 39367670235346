/* eslint-disable */
// This page still needs to be made eslint proof.

import 'datatables.net';
import 'datatables.net-buttons';
import 'datatables.net-buttons/js/buttons.html5.js';

import { max as d3Max, min as d3Min, extent } from 'd3-array';
import { axisBottom, axisLeft } from 'd3-axis';
import { scaleLinear, scaleTime } from 'd3-scale';
import { select } from 'd3-selection';
import { line } from 'd3-shape';
import { zoom as d3Zoom } from 'd3-zoom';
import { DSP, FFT, WindowFunction } from 'dsp.js';
import $ from 'jquery';

import { setLogo } from './graph/graph-helpers';
import { distanceConverter, formatDatetime, getDistanceUnit } from './utils/formatting';
import { upperFirst } from 'lodash-es';

var hidden_lines = [];

function toggleLine() {
    if (this) {
        // Disabled is added to the legend box.
        var line = $(this).attr('class').toLowerCase().replace(' disabled', '');

        // Clicked and not found as hidden so.
        var idx = hidden_lines.indexOf(line);

        if (idx !== -1) {
            hidden_lines.splice(idx, 1);
        } else {
            hidden_lines.push(line);
        }
    }

    $.each(['x', 'y', 'z', 'vector', 'm'], function (_, line) {
        var should_hide = hidden_lines.indexOf(line) == -1;
        $(`.bargraph.${line}, .linegraph.${line}`).toggle(should_hide);
        $('.legend rect.' + line).each(function (index, object) {
            if (should_hide) {
                object.classList.remove('disabled');
            } else {
                object.classList.add('disabled');
            }
        });
    });
}

function addLegend(element, width, add_vector) {
    // Add the legend to the bar graph.
    var legend = element.append('g').attr('class', 'legend');

    var legend_width = 75;
    var lx = width - legend_width;
    var ly = 12;
    var lo = 20;

    legend
        .append('rect')
        .attr('x', lx - 2)
        .attr('y', ly - 6)
        .attr('width', legend_width)
        .attr('height', lo * (add_vector ? 5 : 4))
        .attr('class', 'legend-background');

    var i = 0;
    legend_add(i++, 'x');
    legend_add(i++, 'y');
    legend_add(i++, 'z');
    if (add_vector) {
        legend_add(i++, 'PVS', 'vector');
    }
    legend_add(i++, gettext('Event'), 'm');

    function legend_add(i, title, classname) {
        if (!classname) {
            classname = title;
        }

        legend
            .append('rect')
            .attr('x', lx)
            .attr('y', ly + i * lo)
            .attr('width', 8)
            .attr('height', 8)
            .attr('class', classname);

        legend
            .append('text')
            .attr('x', lx + 15)
            .attr('y', ly + i * lo + 8)
            .attr('class', classname)
            .text(title);
    }
}

const tracePage = (module) => {
    const {
        sensors,
        logo,
        idxToLoad,
        getTraceUrl,
        getTracesUrl,
        start,
        end,
        title,
        dataType,
        overrides,
    } = module.options;

    var traceContentRequest = new XMLHttpRequest();
    var tracesContentRequest = new XMLHttpRequest();
    var traceKeys = null;
    var traces = null;

    function getNewTraceContent(url) {
        traceContentRequest.newRequest(url, handleTraceData);
    }

    function handleTraceData(data) {
        // Load the data for easy referencing.
        if (!data) return;
        var traces = JSON.parse(data);
        if (Object.keys(traces).length == 0) {
            $('#traceGraph').html(gettext('No traces.'));
        }
        var trace = traces[Object.keys(traces)[0]];
        buildGraph(trace);
    }

    function exportConvert(trace) {
        $('#export-content').html('');
        $('#exports #buttons').html($('#exports #no_data').html());
        var odd_even = false;

        // Add header.
        var data_to_export = [];
        var firstDate = null;
        var sensor = null;

        // Convert the data.
        $.each(trace, function (k, v) {
            $.each(v, function () {
                if (!firstDate) firstDate = this.ddate;
                if (!data_to_export[this.ddate])
                    data_to_export[this.ddate] = [];
                if (!data_to_export[this.ddate]['date'])
                    data_to_export[this.ddate]['date'] = new Date(
                        parseInt(this.ddate)
                    ).toISOString();
                if (!sensor) sensor = this.sensor;
                data_to_export[this.ddate][k] = this.val;
            });
        });
        var tbl2 = [];
        tbl2.push('<table>', '<thead>', '<tr>');
        for (var key in data_to_export[firstDate]) {
            tbl2.push('<th>', key.toString(), '</th>');
        }
        tbl2.push('</tr>', '</thead>');

        // Add data.
        for (var key in data_to_export) {
            var row = [];
            row.push('<tr>');
            for (var sub_key in data_to_export[key]) {
                row.push('<td>', data_to_export[key][sub_key], '</td>');
            }
            row.push('</tr>');
            tbl2.push(row.join(''));
        }
        tbl2.push('</table>');

        // Add table to content div.
        $('#export-content').html(tbl2.join(''));

        // Use https://datatables.net/extensions/buttons/examples/html5/simple.html to add export functionality.
        // Add DataTable functionality to table.
        $('#export-content table').DataTable({
            dom: 'Bfrtip',
            buttons: [
                // 'excelHtml5', // Excel takes to long.
                {
                    extend: 'csvHtml5',
                    title:
                        sensor +
                        ' ' +
                        new Date(firstDate).toString().replace(/:/g, '-'),
                },
            ],
        });

        // Clear table buttons.
        $('#exports #buttons').html('');

        // Move table buttons to buttons div.
        $('#exports #buttons')[0].appendChild(
            $('#export-content .dt-buttons')[0]
        );
        $('#exports #buttons button').addClass(
            'pure-button pure-button-primary -smaller'
        );
    }

    function closestBuffer(sample_count) {
        // Returns the closest upper power of 2
        // so give it 5000 and it returns 8192.
        var result = 2;
        sample_count--;
        while ((sample_count >>= 1)) result <<= 1;
        return result;
    }

    function buildFFTGraph(fft_data, sample_rate, origin_buffer_size) {
        var fft_margin = { top: 20, right: 20, bottom: 50, left: 70 },
            fft_width =
                $('h1.with-line')[0].clientWidth -
                fft_margin.left -
                fft_margin.right,
            fft_height = 500 - fft_margin.top - fft_margin.bottom;

        var fft_x = scaleLinear().range([0, fft_width]);
        var fft_y = scaleLinear().range([fft_height, 0]);

        // Define the line.
        var valueline = line()
            .x(function (d) {
                return fft_x(d.freq);
            })
            .y(function (d) {
                return fft_y(distanceConverter(d.val));
            });

        // Prepare the data points.
        var prepare_data = {};
        var step_size = sample_rate / origin_buffer_size;
        for (var key in fft_data) {
            prepare_data[key] = [];

            $.each(fft_data[key], function (index, point) {
                var dot = {};

                // The data needs to be squared
                dot.val = point * 2;
                dot.freq = index * step_size;

                prepare_data[key].push(dot);
            });
        }

        // Append the svg object to the body of the page.
        // Appends a 'group' element to 'svg' and moves
        // the 'group' element to the top left `trace_margin`.
        var fft_svg = select('#fftGraph')
            .append('svg')
            .attr('width', fft_width + fft_margin.left + fft_margin.right)
            .attr('height', fft_height + fft_margin.top + fft_margin.bottom)
            .append('g')
            .attr(
                'transform',
                'translate(' + fft_margin.left + ',' + fft_margin.top + ')'
            );

        var freq_max = [400, 50];
        fft_x.domain([0, freq_max[0]]);
        var maxVal = Math.max(
            d3Max(prepare_data['x'], function (d) {
                return distanceConverter(d.val);
            }),
            d3Max(prepare_data['y'], function (d) {
                return distanceConverter(d.val);
            }),
            d3Max(prepare_data['z'], function (d) {
                return distanceConverter(d.val);
            })
        );
        var minVal = Math.min(
            d3Min(prepare_data['x'], function (d) {
                return distanceConverter(d.val);
            }),
            d3Min(prepare_data['y'], function (d) {
                return distanceConverter(d.val);
            }),
            d3Min(prepare_data['z'], function (d) {
                return distanceConverter(d.val);
            })
        );
        fft_y.domain([minVal, maxVal]);
        fft_svg
            .append('path')
            .data([prepare_data['x']])
            .attr('class', 'linegraph x spectrumline')
            .attr('d', valueline);
        fft_svg
            .append('path')
            .data([prepare_data['y']])
            .attr('class', 'linegraph y spectrumline')
            .attr('d', valueline);
        fft_svg
            .append('path')
            .data([prepare_data['z']])
            .attr('class', 'linegraph z spectrumline')
            .attr('d', valueline);

        // Add the X Axis.
        var x_axis = fft_svg
            .append('g')
            .attr('transform', 'translate(0,' + fft_height + ')')
            .call(axisBottom(fft_x));

        // Add the Y Axis.
        fft_svg.append('g').call(axisLeft(fft_y));

        addLegend(fft_svg, fft_width);
        setLogo(fft_svg, logo);

        // Add X axis title.
        fft_svg
            .append('text')
            .text(gettext('Frequency [Hz]'))
            .attr('x', fft_width / 2 - 28)
            .attr('y', fft_height + 5 + 25 + 5);

        // Add Y axis title.
        let fmt = gettext('Velocity [%(unit)s/s]');
        let text = interpolate(fmt, { unit: getDistanceUnit() }, true);
        fft_svg
            .append('text')
            .text(text)
            .attr('x', (fft_height / 2) * -1)
            .attr('transform', function () {
                return (
                    'rotate(-90, 0, ' +
                    (this.getBBox().height * -1 + fft_margin.left) +
                    ')'
                );
            });

        $('#fftGraph').click(function () {
            var new_max =
                freq_max[
                    (freq_max.indexOf(fft_x.domain()[1]) + 1) % freq_max.length
                ];
            fft_x.domain([0, new_max]);
            x_axis.call(axisBottom(fft_x));
            fft_svg.selectAll('.spectrumline').attr('d', valueline);
        });
    }

    function buildGraph(trace) {
        window.trace = trace;
        $('#traceGraph, #fftGraph').html('');
        if (!trace) {
            console.error(
                'Error: trace data not found? Data might not be imported.'
            );
            return;
        }

        var steps = (trace.end_time - trace.start_time) / trace.y.length;

        // Set the dimensions and margins of the graph.
        var trace_margin = { top: 20, right: 20, bottom: 50, left: 70 },
            trace_width =
                $('h1.with-line')[0].clientWidth -
                trace_margin.left -
                trace_margin.right,
            trace_height = 500 - trace_margin.top - trace_margin.bottom;

        // Set the ranges.
        var trace_x = scaleTime().range([0, trace_width]);
        var trace_x2 = scaleTime().range(trace_x.range());
        var trace_y = scaleLinear().range([trace_height, 0]);

        // 6 seconds / 175 given a nice zoom level. This way each length will get the same zoom level.
        var zoom = d3Zoom()
            .scaleExtent([1, trace.y.length / 175])
            .translateExtent([
                [0, 0],
                [trace_width, trace_height],
            ])
            .extent([
                [0, 0],
                [trace_width, trace_height],
            ])
            .on('zoom', zoomed);

        // Define the line
        var valueline = line()
            .x(function (d) {
                return trace_x(d.date);
            })
            .y(function (d) {
                return trace_y(distanceConverter(d.val));
            });

        var converted_data = {};
        var fft_data = {};

        var buffer_size = closestBuffer(trace['x'].length);
        var axes = ['x', 'y', 'z'];
        var sample_rate = 1000;
        for (var idx in axes) {
            var axis = axes[idx];
            converted_data[axis] = [];

            // Samplerate 1000Hz
            var axis_fft = new FFT(buffer_size, sample_rate);
            var windowing = new WindowFunction(DSP.HANN);
            var samples = [];

            trace[axis].forEach(function (d, index) {
                var dot = {};
                dot.val = d;
                dot.date = new Date(index * steps + trace.start_time);
                dot.ddate = index * steps + trace.start_time;
                dot.sensor = trace.sensor;
                samples.push(d);
                converted_data[axis].push(dot);
            });

            // Pad the data with 0.
            var samples_windowed = windowing.process(samples);
            for (var i = samples_windowed.length; i < buffer_size; i++)
                samples.push(0);

            // Now calculate the fft spectrum.
            axis_fft.forward(samples_windowed);
            fft_data[axis] = axis_fft.spectrum;
        }

        exportConvert(converted_data);
        // Append the svg object to the body of the page.
        // Appends a 'group' element to 'svg' and moves
        // the 'group' element to the top left trace_margin.
        var trace_svg = select('#traceGraph')
            .append('svg')
            .attr('width', trace_width + trace_margin.left + trace_margin.right)
            .attr(
                'height',
                trace_height + trace_margin.top + trace_margin.bottom
            )
            .call(zoom)
            .append('g')
            .attr(
                'transform',
                'translate(' + trace_margin.left + ',' + trace_margin.top + ')'
            );

        var dateRange = extent(converted_data['x'], function (d) {
            return d.date;
        });

        trace_x.domain([trace.start_time, trace.end_time]);
        trace_x2.domain(trace_x.domain());
        var maxVal = Math.max(
            d3Max(converted_data['x'], function (d) {
                return distanceConverter(d.val);
            }),
            d3Max(converted_data['y'], function (d) {
                return distanceConverter(d.val);
            }),
            d3Max(converted_data['z'], function (d) {
                return distanceConverter(d.val);
            })
        );
        var minVal = Math.min(
            d3Min(converted_data['x'], function (d) {
                return distanceConverter(d.val);
            }),
            d3Min(converted_data['y'], function (d) {
                return distanceConverter(d.val);
            }),
            d3Min(converted_data['z'], function (d) {
                return distanceConverter(d.val);
            })
        );
        trace_y.domain([minVal, maxVal]);

        // Create clipping area so the lines won't go over the chart axis.
        trace_svg
            .append('defs')
            .append('clipPath')
            .attr('id', 'barClip')
            .append('rect')
            .attr('width', trace_width)
            .attr('height', trace_height);
        let traces_area = trace_svg
            .append('g')
            .attr('clip-path', 'url(#barClip)')
            .attr('width', trace_width)
            .attr('height', trace_height);

        $.each(['x', 'y', 'z'], function (i, axis) {
            traces_area
                .append('path')
                .data([converted_data[axis]])
                .attr('class', `linegraph ${axis}`)
                .attr('d', valueline);
        });

        var baseLine = line().x(function (d) {
            return trace_x(d.date);
        });
        var yValueFunc = function (multiplier) {
            return function (d) {
                return trace_y(distanceConverter(trace.vkar * (multiplier / 100)));
            };
        };

        // Draw flat level lines.
        if (trace.vkar && trace.vkar > 0.0) {
            traces_area
                .append('svg:path')
                .datum(converted_data['x'])
                .attr('class', 'baseLine')
                .attr('d', baseLine.y(yValueFunc(100)));
            traces_area
                .append('svg:path')
                .datum(converted_data['x'])
                .attr('class', 'baseLine')
                .attr('d', baseLine.y(yValueFunc(-100)));
        }

        // Draw alarm level lines.
        $.each(trace.alarms, function (i, alarm) {
            if (alarm <= 0.0) {
                return;
            }

            traces_area
                .append('svg:path')
                .datum(converted_data['x'])
                .attr('class', 'alarmLine alarmLine' + i)
                .attr('d', baseLine.y(yValueFunc(alarm)));
            traces_area
                .append('svg:path')
                .datum(converted_data['x'])
                .attr('class', 'alarmLine alarmLine' + i)
                .attr('d', baseLine.y(yValueFunc(-alarm)));
        });

        // Add the X Axis.
        trace_svg
            .append('g')
            .attr('transform', 'translate(0,' + trace_height + ')')
            .attr('class', 'x-axis')
            .call(axisBottom(trace_x));

        // Add the Y Axis.
        trace_svg.append('g').call(axisLeft(trace_y));

        addLegend(trace_svg, trace_width);
        setLogo(trace_svg, logo);

        // Add leftmost and rightmost values.
        let leftMostValue = trace_svg
            .append('text')
            .text(formatDatetime(trace_x.domain()[0]));
        leftMostValue.attr('x', 0).attr('y', trace_height + 5 + 25 + 5);

        // Add an indicator for the left value.
        trace_svg
            .append('rect')
            .attr('width', 1)
            .attr('height', 5)
            .attr('x', 0)
            .attr('y', trace_height + 5 + 15);

        // Add rightmost and rightmost values.
        let rightMostValue = trace_svg
            .append('text')
            .text(formatDatetime(trace_x.domain()[1]));
        rightMostValue
            .attr(
                'x',
                trace_width - rightMostValue.node().getComputedTextLength()
            )
            .attr('y', trace_height + 5 + 25 + 5);

        // Add an indicator for the right value.
        trace_svg
            .append('rect')
            .attr('width', 1)
            .attr('height', 5)
            .attr('x', trace_width - 1)
            .attr('y', trace_height + 5 + 15);

        // Add X axis title.
        trace_svg
            .append('text')
            .text(gettext('Date/Time'))
            .attr('x', trace_width / 2 - 28)
            .attr('y', trace_height + 5 + 25 + 5);

        // Add Y axis title.
        let fmt = gettext('Velocity [%(unit)s/s]');
        let text = interpolate(fmt, { unit: getDistanceUnit() }, true);
        trace_svg
            .append('text')
            .text(text)
            .attr('x', (trace_height / 2) * -1)
            .attr('transform', function () {
                return (
                    'rotate(-90, 0, ' +
                    (this.getBBox().height * -1 + trace_margin.left) +
                    ')'
                );
            });

        buildFFTGraph(fft_data, sample_rate, buffer_size);

        // Make a call to toggleLine so the previous state when
        // loading a new graph is loaded again.
        $('g.legend > *').on('click', toggleLine);
        toggleLine();

        function zoomed(event) {
            var t = event.transform;
            trace_x.domain(t.rescaleX(trace_x2).domain());
            trace_svg.selectAll('.linegraph').attr('d', valueline);
            trace_svg.select('.x-axis').call(axisBottom(trace_x));

            // Update lower values.
            leftMostValue.text(formatDatetime(trace_x.domain()[0]));
            rightMostValue.text(formatDatetime(trace_x.domain()[1]));
        }
        window.status = 'graph_loaded';
    }

    function getTraces(url) {
        tracesContentRequest.newRequest(url, handleTracesData);
    }

    function buildLog(traces) {
        $('#traceLog').html('');
        traceKeys = [];
        for (let traceKey in traces) {
            traceKeys.push(traceKey);
        }
        traceKeys.sort();
        var logTable = $(
            "<table class='table table--narrow'><thead><th>" +
                gettext('Time') +
                '</th><th>' +
                upperFirst(gettext('MEASURING_POINT')) +
                '</th><th>' +
                gettext('Duration') +
                '</th></thead></table>'
        );

        var oddEven = true;
        traceKeys.forEach(function (key, idx) {
            oddEven = !oddEven;
            var trace = traces[key];
            var logHtml = '';
            logHtml +=
                "<tr class='clickable " +
                idx +
                ' ' +
                (oddEven ? 'odd' : 'even') +
                "'>";
            logHtml += '<td>' + formatDatetime(trace.start_time) + '</td>';
            logHtml += '<td>' + trace.sensor + '</td>';
            logHtml +=
                '<td>' + (trace.end_time - trace.start_time) / 1000 + 's</td>';
            logHtml += '</tr>';

            var log = $(logHtml);
            log.on('click', () => {
                traceClick(idx);
            });

            logTable.append(log);
        });
        $('#traceLog').append(logTable);

        // Make sure the given number does exist.
        if (!isNaN(idxToLoad) && idxToLoad < traceKeys.length) {
            traceClick(idxToLoad);
        }
        // Make sure there is something to load.
        else if (traceKeys.length > 0) {
            traceClick(traceKeys.length - 1);
        }
    }

    function handleTracesData(data) {
        // Load the data for easy referencing.
        if (!data) return;
        const loadData = (data) => {
            traces = JSON.parse(data);
            if (Object.keys(traces).length == 0) {
                $("#traceLog, #fftGraph, #traceGraph").html(
                    gettext('No traces.')
                );
                return;
            }
            buildLog(traces);
        }
        loadData(data);
    }

    function traceClick(idx) {
        $('#traceGraph, #fftGraph').html(gettext('LOADING'));
        var trace = traces[traceKeys[idx]];
        document.location.hash = '#' + idx;
        $('#traceLog tr.selected').each(function (idx, elem) {
            elem.classList.remove('selected');
        });
        $('#traceLog tr.' + idx)[0].classList.add('selected');
        getNewTraceContent(
            getTraceUrl(sensors, trace.start_time, trace.end_time, dataType, overrides)
        );
    }

    document.title = document.title.replace('TRACETITLE', title);
    $('#traceTitle').html(title);

    $('#traceGraph, #fftGraph, #traceLog').html(gettext('LOADING'));

    getTraces(getTracesUrl(sensors, start, end));
};

export default tracePage;
